import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  CssBaseline,
  Paper,
  Divider,
} from "@mui/material";
import Copyright from "../../components/layout/Copyright";
import LogoPublic from "../../components/layout/LogoPublic";
import LoadingSpinner from "../../components/layout/LoadingSpinner";
import useGetUserCompleteInfo from "../../hooks/users/useGetUserCompleteInfo";
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import DoneIcon from "@mui/icons-material/Done";
import { axiosAuth } from "../../api/axios";
import CloseIcon from "@mui/icons-material/Close";

export default function EmailVerification() {
  const { otp } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const handleVerification = async () => {
    try {
      const response = await axiosAuth.post("/confirm_registration", {
        key: otp,
      });
      if (response) {
        setLoading(false);
        setTimeout(() => (window.location.href = "/login"), 3000);
      }
    } catch (err) {
      setLoading(false)
      setError(true);
    }
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#005ca9";

    setTimeout(() => handleVerification(), 1000);
  }, []);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          mt: isMobile ? 4 : 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <LogoPublic />
        <Paper
          elevation={6}
          sx={{
            mt: 1,
            p: isMobile ? 2 : 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography component="h1" variant="h5">
            {loading
              ? "Validazione account in corso"
              : error
              ? "Errore, link non valido!"
              : "Account attivato con successo!"}
          </Typography>
          <Divider sx={{ m: 4 }} />
          {loading ? (
            <LoadingSpinner />
          ) : error ? (
            <>
              <CloseIcon sx={{ scale: "500%", color: "red" }} />
              <Divider sx={{ m: 4 }} />
              <Typography variant="h6" sx={{ mb: -5 }}>
                Contatta il supporto
              </Typography>
            </>
          ) : (
            <>
              <DoneIcon sx={{ scale: "500%", color: "#005ca9" }} />
              <Divider sx={{ m: 4 }} />
              <Typography variant="h6" sx={{ mb: -5 }}>
                Ti reindirizzeremo alla schermata di accesso
              </Typography>
            </>
          )}
          <Divider sx={{ m: 4 }} />
          <Copyright />
        </Paper>
      </Box>
    </Container>
  );
}
