import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import LoadingSpinner from "../layout/LoadingSpinner";

const UsersNew = ({ user }) => {
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [nameInput, setNameInput] = useState("");
  const [surnameInput, setSurnameInput] = useState("");
  const [businessNamelInput, setBusinessNameInput] = useState("");
  const [pIvaInput, setPIvaInput] = useState("");
  const [addressInput, setAddressInput] = useState("");
  const [telephoneInput, setTelephoneInput] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [showCredentials, setShowCredentials] = useState(false);
  const [generatedPSW, setGeneratedPSW] = useState("")

  const emailRegex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  const alphabeticWithSpecialCharsRegex = /^[a-zA-ZÀ-ÖØ-öø-ÿ' -]{1,50}$/;
  const alphanumericalWithAllSpecialCharsRegex =
    /^[0-9a-zA-ZÀ-ÖØ-öø-ÿ!@#$%^&*(),.?":{}|<>_\-\\/[\]';\s]{1,50}$/;
  const pIvaRegex = /^\d{11}$/;
  const phoneNumberRegex = /^[+\d\s()-]{7,15}$/;

  const generateRandomString = () => {
    const characters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_=+[]{};:,.<>?";

    let randomString = "";
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }

    return randomString;
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const randomPSW = generateRandomString();

      const result = await axiosPrivate.post(
        `/Users/create_complete?role=${selectedRole}`,
        {
          email: emailInput,
          password: randomPSW,
          name: nameInput,
          surname: surnameInput,
          business_name: businessNamelInput,
          telephone: telephoneInput,
          p_iva: pIvaInput,
          address: addressInput,
        }
      );
      if (result) {
        setGeneratedPSW(randomPSW)
        setShowCredentials(true);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };

  const isFormValid = () => {
    return (
      emailRegex.test(emailInput) &&
      selectedRole !== "" &&
      businessNamelInput !== ""
    );
  };
  return (
    <Container component={Paper} maxWidth={"md"} sx={{ py: 2 }}>
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ my: 3 }}>
        <TextField
          size="small"
          label="Nome"
          value={nameInput}
          error={
            !alphabeticWithSpecialCharsRegex.test(nameInput) &&
            nameInput.length > 0
          }
          helperText={
            !alphabeticWithSpecialCharsRegex.test(nameInput) &&
            nameInput.length > 0 &&
            "Nome non valido"
          }
          onChange={(e) => setNameInput(e.target.value)}
          sx={{ m: 1, width: "45%" }}
        />
        <TextField
          size="small"
          label="Cognome"
          value={surnameInput}
          error={
            !alphabeticWithSpecialCharsRegex.test(surnameInput) &&
            surnameInput.length > 0
          }
          helperText={
            !alphabeticWithSpecialCharsRegex.test(surnameInput) &&
            surnameInput.length > 0 &&
            "Cognome non valido"
          }
          onChange={(e) => setSurnameInput(e.target.value)}
          sx={{ m: 1, width: "45%" }}
        />
        <TextField
          size="small"
          required
          label="Ragione Sociale"
          value={businessNamelInput}
          error={
            !alphanumericalWithAllSpecialCharsRegex.test(businessNamelInput) &&
            businessNamelInput.length > 0
          }
          helperText={
            !alphanumericalWithAllSpecialCharsRegex.test(businessNamelInput) &&
            businessNamelInput.length > 0 &&
            "Ragione Sociale non valida"
          }
          onChange={(e) => setBusinessNameInput(e.target.value)}
          sx={{ m: 1, width: "65%" }}
        />
        <TextField
          size="small"
          label="Partita IVA"
          value={pIvaInput}
          error={!pIvaRegex.test(pIvaInput) && pIvaInput.length > 0}
          helperText={
            !pIvaRegex.test(pIvaInput) &&
            pIvaInput.length > 0 &&
            "Formato partita iva non valido"
          }
          onChange={(e) => setPIvaInput(e.target.value)}
          sx={{ m: 1, width: "25%" }}
        />
        <TextField
          size="small"
          label="Indirizzo"
          value={addressInput}
          error={
            !alphanumericalWithAllSpecialCharsRegex.test(addressInput) &&
            addressInput.length > 0
          }
          helperText={
            !alphanumericalWithAllSpecialCharsRegex.test(addressInput) &&
            addressInput.length > 0 &&
            "Indirizzo non valido"
          }
          onChange={(e) => setAddressInput(e.target.value)}
          sx={{ m: 1, width: "65%" }}
        />
        <TextField
          size="small"
          label="Telefono"
          value={telephoneInput}
          error={
            !phoneNumberRegex.test(telephoneInput) && telephoneInput.length > 0
          }
          helperText={
            !phoneNumberRegex.test(telephoneInput) &&
            telephoneInput.length > 0 &&
            "Numero di telefono non valido"
          }
          onChange={(e) => setTelephoneInput(e.target.value)}
          sx={{ m: 1, width: "25%" }}
        />
        <TextField
          size="small"
          required
          label="Email"
          value={emailInput}
          error={!emailRegex.test(emailInput) && emailInput.length > 0}
          helperText={
            !emailRegex.test(emailInput) &&
            emailInput.length > 0 &&
            "Email non valida"
          }
          onChange={(e) => setEmailInput(e.target.value)}
          sx={{ m: 1, width: "65%" }}
        />
        <FormControl size="small" sx={{ m: 1, width: "25%" }}>
          <InputLabel id="select-label">Tipo utente *</InputLabel>
          <Select
            labelId="select-label"
            label="Tipo utente *"
            onChange={(e) => setSelectedRole(e.target.value)}
          >
            <MenuItem value="Repairer">Riparatore</MenuItem>
            <MenuItem value="Intermediary">Perito</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ width: "100%" }}>
          <Typography variant="caption">* Campi obbligatori</Typography>
        </Box>
        {showCredentials === false ? (
          <Button
          size="large"
            disabled={!isFormValid() || loading}
            variant="contained"
            onClick={handleSubmit}
            sx={{ mt: 3, mb: 5, width: "92%" }}
          >
            {loading ? <LoadingSpinner /> : "Crea utente"}
          </Button>
        ) : (
          <Box sx={{ background: "lightgreen", borderRadius: 5, pb: 5 }}>
            <br />
            <h4>
              Nuovo{" "}
              {selectedRole === "Repairer"
                ? "riparatore"
                : selectedRole === "Intermediary" && "perito"}{" "}
              creato con successo!
            </h4>
            Custodire le seguenti credenziali con cura:
            <br />
            Email: <b>{emailInput}</b>
            <br />
            Password: <b>{generatedPSW}</b>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default UsersNew;
