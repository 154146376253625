import React from "react";

import { useNavigate } from "react-router-dom";

import { Box, Button } from "@mui/material";

import Logo from "../../../logo.svg";
import LogoutBtn from "../buttons/singleButtons/LogoutBtn";
import AdminBtns from "../buttons/AdminBtns";
import ControllerBtns from "../buttons/ControllerBtns";
import IntermediaryBtns from "../buttons/IntermediaryBtns";
import RepairerBtns from "../buttons/RepairerBtns";
import CnaBtns from "../buttons/CnaBtns";
import NotificationsMenu from "../buttons/singleButtons/NotificationsMenu";

const DesktopNav = ({ type, userData }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: "100px",
        display: "flex",
        justifyContent: "space-between",
        mt: 1,
        px: "6.5%",
      }}
    >
      <Box sx={{ width: 200, mr: 5, ml: -5 }}>
        <Button onClick={() => navigate("/")}>
          <img src={Logo} alt="Logo" style={{ width: "110%" }} />
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        {type === "9495" ? (
          <AdminBtns type={type} />
        ) : type === "5672" ? (
          <CnaBtns type={type} />
        ) : type === "8711" ? (
          <ControllerBtns type={type} />
        ) : type === "2039" ? (
          <IntermediaryBtns type={type} />
        ) : (
          type === "6093" && <RepairerBtns type={type} />
        )}
      </Box>
      <Box sx={{ mt: 2, ml: "5%", mr: "-5%", width: 200, textAlign: "right", color: "white" }}>
        <b style={{ fontSize: "10pt" }}>{userData["user_anagraphics.business_name"]}</b>
        <br />
        {type !== "9495" && type !== "5672" && <NotificationsMenu />} 
        <LogoutBtn />
      </Box>
    </Box>
  );
};

export default DesktopNav;
