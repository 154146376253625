import React, { useEffect, useRef } from "react";

import { Box } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import GavelIcon from "@mui/icons-material/Gavel";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import {
  AppBar,
  Dialog,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
} from "@mui/material";

import useGetListOf from "../../hooks/useGetListOf";

const PracticesStatesHistoryModal = ({
  open,
  onClose,
  practiceCode,
  title,
}) => {
  const practiceProgressesList = useGetListOf(
    "Practice_Progresses",
    `where_key=practice_code&where_value=${practiceCode}&complete=true&order_key=created_at`,
    "list"
  ).listElements;

  const scrollBoxRef = useRef(null);

  useEffect(() => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollTop = scrollBoxRef.current.scrollHeight;
    }
  }, [practiceProgressesList]);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const handleConvertTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    if (isNaN(date.getTime())) {
      console.error("Timestamp non valido:", timestamp);
      return "Data non disponibile";
    }

    let formattedDate = new Intl.DateTimeFormat("it-IT", {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(date);

    formattedDate = formattedDate
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    formattedDate = formattedDate.replace("Alle", "alle");

    return formattedDate;
  };

  const getIconAndColor = (status) => {
    switch (status) {
      case "Nuova":
        return { icon: <NewReleasesIcon />, color: "#42c4f5" };
      case "Presa in carico":
        return { icon: <HowToRegIcon />, color: "#42a5f5" };
      case "Richiesta danni inviata":
        return {
          icon: (
            <>
              <DriveFileMoveIcon />
              <AccountBalanceIcon />
            </>
          ),
          color: "purple",
        };
      case "Attesa esito perizia":
        return {
          icon: (
            <>
              <PersonSearchIcon />
              <HourglassTopIcon />
            </>
          ),
          color: "goldenrod",
        };
      case "Perizia conclusa":
        return {
          icon: (
            <>
              <PersonSearchIcon />
              <MinorCrashIcon />
            </>
          ),
          color: "#85d016",
        };
      case "Trattativa con liquidatore":
        return {
          icon: (
            <>
              <CurrencyExchangeIcon />
              <AccountBalanceIcon />
            </>
          ),
          color: "darkorchid",
        };
      case "Liquidata":
        return { icon: <DoneIcon />, color: "green" };
      case "Rigettata":
        return { icon: <CloseIcon />, color: "red" };
      case "Contenzioso":
        return { icon: <GavelIcon />, color: "orangered" };
      default:
        return { icon: null, color: "gray" };
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <AppBar sx={{ position: "relative", overflow: "hidden" }}>
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h6" component="div">
            Cronologia stati pratica{" "}
            {title && (
              <i>
                <br />
                {title}
              </i>
            )}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          p: 2,
          height: 500,
          overflow: "auto",
          background: "#f9f9f9",
          borderRadius: 2,
        }}
        ref={scrollBoxRef}
      >
        <Stepper
          activeStep={practiceProgressesList.length - 1}
          orientation="vertical"
          sx={{ mx: "auto" }}
        >
          {practiceProgressesList.map((progress, index) => {
            const { icon, color } = getIconAndColor(
              progress["practice_states.value"]
            );
            const isActive = practiceProgressesList.length - 1 === index;

            return (
              <Step key={index} active={isActive}>
                <StepLabel
                  icon={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color,
                      }}
                    >
                      {icon}
                    </Box>
                  }
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      borderRadius: 2,
                      px: 2,
                      py: 1,
                      backgroundColor: isActive ? "azure" : "#f1f1f1",
                      border: isActive ? "2px solid #005ca9" : "1px solid #ccc",
                      boxShadow: isActive
                        ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                        : "none",
                      width: "100%",
                    }}
                  >
                    {isActive && (
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        fontStyle={"italic"}
                      >
                        Stato attuale
                      </Typography>
                    )}
                    <Typography
                      variant="body1"
                      fontWeight={isActive ? 900 : "bold"}
                      sx={{ color }}
                    >
                      {progress["practice_states.value"]}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      fontWeight={isActive && "bold"}
                    >
                      {handleConvertTimestamp(
                        progress["practice_progresses.created_at"]
                      )}
                    </Typography>
                  </Box>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </Dialog>
  );
};

export default PracticesStatesHistoryModal;
