import React from "react";

import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";

const DatabaseBtn = () => {
  return (
    <Button
      fullWidth={isMobile || (window.innerWidth <= 785 && true)}
      onClick={() => (window.location.href = "/tables")}
      sx={{ color: !isMobile && "white", borderBottom: isMobile && "1px solid #ddd", mb: isMobile && 1 }}
    >
      DATABASE
    </Button>
  );
};

export default DatabaseBtn;
