import React from "react";
import NavBar from "../../../../components/navbar/NavBar";
import UsersDetail from "../../../../components/users/UsersDetail";
import { useParams } from "react-router-dom";

const ControllerUsersDetail = ({ type }) => {
    const { codeUser } = useParams();

  return (
    <>
      <NavBar type="8711" />
      <UsersDetail user="Controller" userCode={codeUser} userType={type}/>
    </>
  );
};

export default ControllerUsersDetail;
