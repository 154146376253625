import { useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { handleCreateErrors } from "../../../components/utils/errors"

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useGetUnlimitedListOf from "../../../hooks/useGetUnlimitedListOf";


const Create = ({ handleOpen, open }) => {
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();

  const table = "Practice_Progresses";

  const [selectedpractice_code, setSelectedpractice_code] = useState(null);
  const [selectedpractice_state_id, setSelectedpractice_state_id] = useState(null);
  const practicesTemp = useGetUnlimitedListOf("Practices", "", "list");
  const practicesData = practicesTemp.listElements;

  const practice_statesTemp = useGetUnlimitedListOf("Practice_States", "", "list");
  const practice_statesData = practice_statesTemp.listElements;

  const columns = ["id", "practice_code", "practice_state_id"];

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const create = async (formData) => {
if (!formData.practice_code?.trim() || formData.practice_state_id === null) {
    window.alert("practice_code, practice_state_id non possono essere vuoti!");
    return;
  }


	 const errors = {}
	 const apiCall = `/${table}/create`
    try {
      const response = await axiosPrivate.post(apiCall, formData);
      if (!response) throw new Error("Errore nella creazione");

      window.alert("Creazione avvenuta con successo")

    } catch (error) {
    errors.call = apiCall;
    errors.callData = formData;
    errors.error = error;
    errors.forceReload = false;

    handleCreateErrors(errors, true)

    }
  };

  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
          }}
          alignItems={"center"}
        >
          <Box display={"flex"} justifyContent={"space-between"} sx={{ mb: 2 }}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Crea un nuovo elemento per la tabella {table}
            </Typography>
            <Button
              color="error"
              onClick={() => handleOpen(false, "create")}
              sx={{
                maxWidth: "30px",
                maxHeight: "30px",
                minWidth: "30px",
                minHeight: "30px",
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{ width: "100%" }}
          >
            {/* Autocomplete per il campo practice_code */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={practicesData}
              getOptionLabel={(option) => option.title || option.code}
              value={
                selectedpractice_code
                  ? practicesData.find((practice) => practice.code === selectedpractice_code)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedpractice_code(newValue.code);
                  setValue("practice_code", newValue.code);
                }
              }}
              isOptionEqualToValue={(option, value) =>
                option.code === value.code
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select practice_code"
                  placeholder="practice_code"
                  error={!!errors.practice_code}
                  helperText={
                    errors.practice_code ? "Il campo practice_code è obbligatorio" : ""
                  }
                />
              )}
            />
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{ width: "100%" }}
          >
            {/* Autocomplete per il campo practice_state_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={practice_statesData}
              getOptionLabel={(option) => option.value || option.id}
              value={
                selectedpractice_state_id
                  ? practice_statesData.find((practice) => practice.id === selectedpractice_state_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedpractice_state_id(newValue.id);
                  setValue("practice_state_id", newValue.id);
                }
              }}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select practice_state_id"
                  placeholder="practice_state_id"
                  error={!!errors.practice_state_id}
                  helperText={
                    errors.practice_state_id ? "Il campo practice_state_id è obbligatorio" : ""
                  }
                />
              )}
            />
          </Box>

          {/* Altri campi */}
          {columns
            .filter((column) => column !=="id" && column !== "practice_code" && column !== "practice_state_id")
            .map((column, index) => (
              <TextField
                key={index}
                placeholder={column}
                sx={{ width: "50%", m: 1 }}
                {...register(column)}
              />
            ))}


          <Button onClick={handleSubmit(create)}>CREA ENTRY</Button>
        </Box>
      </Modal>
    </>
  );
};

export default Create;