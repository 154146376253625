import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Divider } from "@mui/material";
import { Box } from "@mui/system";

function Copyright(props) {
  return (
    <React.Fragment>
      <Box sx={{ mt: 5 }} />
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
        sx={{ bottom: 10, position: "relative", mx: 1, ...props.sx }}
      >
        <b>{"CNA Nazionale"}</b>
        <br />
        <br />
        {"Piazza M. Armellini, 9A, 00162 Roma — C.F. 07987330581"}
        <br />
        {"Tel. 06 441881 — Fax. 06 44249511 — Email cna@cna.it"}
        <br />
        {"© " + new Date().getFullYear() + " "}
        <Link color="inherit" href="https://cna.it/" target="_blank">
          CNA Nazionale
        </Link>
        {" - Tutti i diritti riservati."}
        <br />
        <br />
        <br />
        <i>
          <Link color="inherit" href="https://metakol.com/" target="_blank" fontSize={12}>
            Powered by Metakol SrL 
          </Link>
        </i>
      </Typography>
    </React.Fragment>
  );
}

export default Copyright;
