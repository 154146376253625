import { Box } from "@mui/system";
import React from "react";
import useGetPracticesStats from "../../hooks/practices/useGetPracticesStats";
import { Button, Typography } from "@mui/material";

import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import GavelIcon from "@mui/icons-material/Gavel";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

const PracticesCounterDisplayer = ({ user, status, userCode, timeQuery }) => {
  const practicesCount = useGetPracticesStats(status, userCode, timeQuery);

  return (
    <Box
      disabled={userCode}
      component={Button}
      onClick={() => {
        if (user === "Controller")
          window.location.href = `/area_controller/pratiche?where_key=status&where_value=${status}`;
      }}
      sx={{
        display: "block",
        textDecoration: "none",
        transition: "transform 0.2s ease-in-out",
        "&:hover": {
          transform: "scale(1.1)",
          border: "5px solid #005ca9",
          boxShadow: 10,
        },
        "&:active": {
          transform: "translateY(-20px)",
          transition: "transform 0.2s",
        },
        "&:disabled": { color: "white" },
        color: "white",
        background:
          status === "Nuova"
            ? "#42c4f5"
            : status === "Presa in carico"
            ? "#42a5f5"
            : status === "Richiesta danni inviata"
            ? "purple"
            : status === "Attesa esito perizia"
            ? "goldenrod"
            : status === "Perizia conclusa"
            ? "#85d016"
            : status === "Trattativa con liquidatore"
            ? "darkorchid"
            : status === "Liquidata"
            ? "green"
            : status === "Rigettata"
            ? "red"
            : status === "Contenzioso" && "orangered",
        width: "32%",
        height: 100,
        mb: 1,
        borderRadius: 2,
        boxShadow: 2,
        p: 1,
      }}
    >
      {status === "Nuova" ? (
        <NewReleasesIcon />
      ) : status === "Presa in carico" ? (
        <HowToRegIcon />
      ) : status === "Richiesta danni inviata" ? (
        <>
          <DriveFileMoveIcon />
          <AccountBalanceIcon />
        </>
      ) : status === "Attesa esito perizia" ? (
        <>
          <PersonSearchIcon />
          <HourglassTopIcon />
        </>
      ) : status === "Perizia conclusa" ? (
        <>
          <PersonSearchIcon />
          <MinorCrashIcon />
        </>
      ) : status === "Trattativa con liquidatore" ? (
        <>
          <CurrencyExchangeIcon />
          <AccountBalanceIcon />
        </>
      ) : status === "Liquidata" ? (
        <DoneIcon />
      ) : status === "Rigettata" ? (
        <CloseIcon />
      ) : (
        status === "Contenzioso" && <GavelIcon />
      )}
      <br />
      <Typography
        variant={isMobile ? "subtitle1" : "h6"}
        sx={{
          mt: !isMobile && -1,
          lineHeight: isMobile && 1,
          fontWeight: isMobile && 100,
        }}
      >
        {status}
      </Typography>
      <Typography variant="h5">{practicesCount}</Typography>
    </Box>
  );
};

export default PracticesCounterDisplayer;
