import React, { useEffect } from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid2 from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import { axiosAuth } from "../../api/axios";
import Copyright from "../../components/layout/Copyright";
import LogoPublic from "../../components/layout/LogoPublic";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../components/layout/LoadingSpinner";
import { Paper } from "@mui/material";
import { isMobile } from "react-device-detect";

export default function PswReset() {
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = "#005ca9";
  }, []);

  const handleSubmit = (event) => {
    setLoading(true);

    event.preventDefault();
    const data = new FormData(event.currentTarget);

    console.log({
      email: data.get("email"),
    });

    axiosAuth
      .post("/send_otp", { email: data.get("email") })
      .then((response) => {
        window.alert("Richiesta inviata con successo!");
        window.location.href = "/";
      })
      .catch((error) => {
        setLoading(false);
        console.log("error: ", error);
        window.alert("Si � verificato un problema. Riprova.");
      });
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          pt: 5,
          pb: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box width={"100%"}>
          <LogoPublic />
        </Box>
        <Paper
          elevation={6}
          sx={{
            mt: 1,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: isMobile ? "80%" : "100%",
          }}
        >
          <Typography variant="h5">Reimposta password</Typography>
          <Divider sx={{ my: 1 }} />
          <Typography variant="h7">
            Inserisci il tuo indirizzo email per ricevere il link al reset della
            password del tuo account
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3, width: "100%" }}
          >
            <TextField
              required
              id="email"
              label="Indirizzo Email"
              name="email"
              autoComplete="email"
			  sx={{ width: "100%" }}
            />
            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Invio
                </Button>
                <Button size="small" component={Link} variant="contained" color="error" to="/">Annulla</Button>
              </>
            )}
          </Box>
          <Copyright />
        </Paper>
      </Box>
    </Container>
  );
}
