import React, { useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";

import LogoutBtn from "../buttons/singleButtons/LogoutBtn";
import ProfileBtn from "../buttons/singleButtons/ProfileBtn";
import DatabaseBtn from "../buttons/singleButtons/DatabaseBtn";
import LogoPublic from "../../layout/LogoPublic";
import AdminBtns from "../buttons/AdminBtns";
import ControllerBtns from "../buttons/ControllerBtns";
import IntermediaryBtns from "../buttons/IntermediaryBtns";
import RepairerBtns from "../buttons/RepairerBtns";
import { isMobile } from "react-device-detect";
import Copyright from "../../layout/Copyright";
import CnaBtns from "../buttons/CnaBtns";
import NotificationsMenu from "../buttons/singleButtons/NotificationsMenu";

const MobileNav = ({ type, userData }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        zIndex: 1001,
      }}
    >
      <Accordion
        expanded={open}
        onChange={handleToggle}
        elevation={0}
        sx={{
          width: "100%",
          height: "88px",
          backgroundColor: "#005ca9",
          // boxShadow: "inset 0px 1px 1px grey",
        }}
      >
        <AccordionSummary
          sx={{ pt: 3, pb: 2 }}
          expandIcon={
            open ? (
              <CloseIcon sx={{ color: "white", fontSize: 40 }} />
            ) : (
              <MenuIcon sx={{ color: "white", fontSize: 40 }} />
            )
          }
        >
          <Box sx={{ scale: "80%", position: "absolute", top: 8, left: -74 }}>
            <LogoPublic />
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            pt: 2,
            borderBottom: "1px solid black",
            boxShadow: "0 4px 2px -2px black",
            backgroundColor: "white",
          }}
        >
          <Typography variant="caption">CNA Gestione Sinistro</Typography>
          <Typography
            variant="h6"
            sx={{ borderBottom: isMobile && "1px solid #ddd" }}
          >
            {userData["user_anagraphics.business_name"]}
          </Typography>
          <Box sx={{ mt: 2 }}>
            {type === "9495" ? (
              <AdminBtns type={type} />
            ) : type === "5672" ? (
              <CnaBtns type={type} />
            ) : type === "8711" ? (
              <ControllerBtns type={type} />
            ) : type === "2039" ? (
              <IntermediaryBtns type={type} />
            ) : (
              type === "6093" && <RepairerBtns type={type} />
            )}
            <br />
            <br />
            <br />
            <Copyright />
          </Box>
        </AccordionDetails>
      </Accordion>
      
    </Box>
    <Box sx={{ position: "absolute", right: "14%", top: "2.5%", zIndex: 10000 }}>
    {type !== "9495" && type !== "5672" && <NotificationsMenu />} 
  </Box>
  </>
  );
};

export default MobileNav;
