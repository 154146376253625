import {
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useState } from "react";
import useGetUserCompleteInfo from "../../hooks/users/useGetUserCompleteInfo";
import ElementsList from "../ElementsList";
import PracticesStats from "../practices/PracticesStats";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UsersDetail = ({ user, userCode, userType }) => {
  const [value, setValue] = useState(0);
  const userData = useGetUserCompleteInfo(userCode);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  if (userData.length !== 0)
    return (
      <Box
        //   maxWidth="xl"
        //   component={Paper}
        sx={{
          pt: 1,
          px: 0,
          display: !isMobile && "flex",
          mt: isMobile ? 10 : 3,
          mb: 1,
        }}
      >
        <Container maxWidth="sm" component={Paper}>
          {/* <Box
          sx={{
            p: 2,
            height: 500,
            overflow: "auto",
            background: "#f9f9f9",
            borderRadius: 2,
          }}
        > */}
          <Typography variant="h5" color="primary" sx={{ mb: 1, pt: 2 }}>
            {userType === "Repairer"
              ? "Dettaglio riparatore"
              : userType === "Intermediary" && "Dettaglio perito"}
          </Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ fontWeight: "bold", width: "30%" }}>
                  Ragione Sociale
                </TableCell>
                <TableCell>
                  <Typography variant={isMobile ? "h6" : "h5"} color="primary">
                    {userData.user_anagraphic.business_name}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>
                  Partita IVA
                </TableCell>
                <TableCell>{userData.user_anagraphic.p_iva}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Indirizzo</TableCell>
                <TableCell>{userData.user_anagraphic.address}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Nome</TableCell>
                <TableCell>{userData.user_anagraphic.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Cognome</TableCell>
                <TableCell>{userData.user_anagraphic.surname}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>
                  Indirizzo Mail
                </TableCell>
                <TableCell>{userData.user.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Telefono</TableCell>
                <TableCell>{userData.user_anagraphic.telephone}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {/* </Box> */}
        </Container>
        <Container maxWidth="md" component={Paper}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            variant="fullWidth"
            //   orientation={isMobile && "vertical"}
            TabIndicatorProps={{
              sx: {
                width: "96%",
                zIndex: 0,
                left: "2%",
                borderRadius: 2,
                boxShadow: 2,
              },
            }}
          >
            <Tab
              sx={{ background: "white" }}
              label={
                <Typography
                  variant="h6"
                  fontSize={15}
                  // sx={{ zIndex: 1, color: value === 0 && isMobile && "white" }}
                >
                  Statistiche
                </Typography>
              }
              {...a11yProps(0)}
            />
            {user !== "Cna" && (
              <Tab
                sx={{ background: "white" }}
                label={
                  <Typography
                    variant="h6"
                    fontSize={15}
                    // sx={{ zIndex: 1, color: value === 1 && isMobile && "white" }}
                  >
                    Lista pratiche
                  </Typography>
                }
                {...a11yProps(1)}
              />
            )}
          </Tabs>
          <Box sx={{ mt: -2 }}>
            <CustomTabPanel value={value} index={0}>
              <Box sx={{ mt: isMobile ? -15 : -1, mb: -5, ml: -6, mr: -6 }}>
                <PracticesStats user="Controller" userCode={userCode} />
              </Box>
            </CustomTabPanel>

            {user !== "Cna" && (
              <CustomTabPanel value={value} index={1}>
                <Box sx={{ mt: isMobile ? -2 : -1, ml: -6, mr: -6 }}>
                  <ElementsList
                    type="Practices"
                    user="Controller"
                    variantSearchKeys={`where_key=user_code&where_value=${userCode}&order_key=created_at&order_desc=true`}
                  />
                </Box>
              </CustomTabPanel>
            )}
          </Box>
        </Container>
      </Box>
    );
};

export default UsersDetail;
