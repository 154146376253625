import React from "react";
import { useState } from "react";

import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import { Box, Paper, TextField } from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { Divider, Grid2, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import NavBar from "../navbar/NavBar";
import LoadingSpinner from "../layout/LoadingSpinner";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const PSW_MIN_LENGTH = 8;

const ProfileDataChangePsw = ({ userData }) => {
  const axiosPrivate = useAxiosPrivate();

  const [isActive, setIsActive] = useState(false);
  const [pswInput, setPswInput] = useState("");
  const [rePswInput, setRePswInput] = useState("");
  const [oldPswInput, setOldPswInput] = useState("");
  let [loading, setLoading] = useState(false);
  // let [pswdsEqual, setPswdsEqual] = useState(false);
  let [pswdsOk, setPswdsOk] = useState(false);

  var psw = document.getElementById("new_password");
  var rePsw = document.getElementById("retyped-password");
  var oldPsw = document.getElementById("old_password");

  function showPswds() {
    if (psw?.type === "password" && rePsw?.type === "password") {
      setIsActive(true);
      psw.type = "text";
      rePsw.type = "text";
      oldPsw.type = "text";
    } else if (psw.type === "text" && rePsw.type === "text") {
      setIsActive(false);
      psw.type = "password";
      rePsw.type = "password";
      oldPsw.type = "password";
    }
  }

  function checkPswds() {
    if (psw?.value !== "" || rePsw?.value !== "") {
      if (
        psw?.value.length >= PSW_MIN_LENGTH &&
        rePsw?.value.length >= PSW_MIN_LENGTH &&
        oldPsw?.value.length >= PSW_MIN_LENGTH
      ) {
        if (psw?.value !== rePsw?.value) {
          // setPswdsEqual(false);
          setPswdsOk(false);
        } else {
          // setPswdsEqual(true);

          if (oldPsw?.value.length >= PSW_MIN_LENGTH) setPswdsOk(true);
          else setPswdsOk(false);
        }
      } else {
        // setPswdsEqual(false);
        setPswdsOk(false);
      }
    }
  }

  const handleSubmit = async (event) => {
    setLoading(true);

    event.preventDefault();

    await axiosPrivate
      .post(
        "/auth_secure/change_password",
        JSON.stringify({
          email: userData.user["email"],
          old_password: oldPsw.value,
          new_password: psw.value,
        })
      )
      .then((response) => {
        if (response?.status === 200) {
          window.alert("Password cambiata");
          window.location.href = "/profilo";
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error: ", error);
        if (error.response?.status === 400) {
          window.alert(error.response.data?.detail);
        } else {
          window.alert("Si è verificato un problema. Riprova.");
        }
      });
  };

  return (
    <Container component={Paper} maxWidth={"sm"} sx={{ py: 2 }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Inserisci la tua nuova password</Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="h7">
          La password deve contenere minimo 8 caratteri.
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{ mt: 3, width: "100%" }}
        >
          <TextField
            required
            fullWidth
            type="password"
            id="new_password"
            label="Nuova password"
            autoComplete="new_password"
            error={psw?.value.length < 8 && psw?.value.length > 0}
            helperText={
              psw?.value.length < 8 &&
              psw?.value.length > 0 &&
              "La password deve contenere almeno 8 caratteri."
            }
            value={pswInput}
            onChange={(e) => {
              setPswInput(e.target.value);
              checkPswds();
            }}
            sx={{ mb: 1 }}
          />
          <TextField
            required
            fullWidth
            type="password"
            id="retyped-password"
            label="Ripeti la nuova password"
            autoComplete="retyped-password"
            error={psw?.value !== rePsw?.value}
            helperText={
              psw?.value !== rePsw?.value && "Le password devono coincidere."
            }
            value={rePswInput}
            onChange={(e) => {
              setRePswInput(e.target.value);
              checkPswds();
            }}
            sx={{ mb: 1 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button tabindex="-1" onClick={() => showPswds(!isActive)}>
                    {isActive ? (
                      <VisibilityOffIcon fontSize="large" />
                    ) : (
                      <VisibilityIcon fontSize="large" />
                    )}
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            required
            fullWidth
            type="password"
            id="old_password"
            label="Inserisci la password attuale per confermare"
            autoComplete="old_password"
            value={oldPswInput}
            onChange={(e) => {
              setOldPswInput(e.target.value);
              checkPswds();
            }}
            sx={{ mb: 1, mt: 2 }}
          />
          {pswdsOk ? (
            loading ? (
              <LoadingSpinner />
            ) : (
              <Button
                size="large"
                id="submitBtn"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Invio
              </Button>
            )
          ) : (
            <>
              <Button
                size="large"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled
              >
                Invio
              </Button>
              <Button
                size="small"
                component={Link}
                to="/profilo"
                variant="contained"
                color="error"
                sx={{ mx: 2, width: "40%" }}
              >
                Annulla
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default ProfileDataChangePsw;
