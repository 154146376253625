import { Button } from '@mui/material'
import React from 'react'
import { isMobile } from 'react-device-detect'

const CnaStatsBtn = () => {
  return (
    <Button
      fullWidth={(isMobile || window.innerWidth <= 785) && true}
      onClick={() => (window.location.href = "/statistiche_pratiche")}
      sx={{ color: !isMobile && "white", borderBottom: isMobile && "1px solid #ddd", mb: isMobile && 1 }}
    >
      STATISTICHE
    </Button>
  )
}

export default CnaStatsBtn