import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../useAxiosPrivate";

const useGetPracticesStats = (status, userCode, query) => {
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);


  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if(status){
        if(query === "" || typeof query === "undefined")
            query = `?status_value=${status}`
        else
            query = query+`&status_value=${status}`
    }
    if(userCode){
        if(query === "" || typeof query === "undefined")
            query = `?user_code=${userCode}`
        else
            query = query+`&user_code=${userCode}`
    }

    const infos = async () => {
      console.log(status, userCode)
      console.log(query)
      try {
        const response = await axiosPrivate.get(`/Practices/get_stats${query}`);
        if (isMounted) {
          setData(response.data?.result.total_element);
        }
      } catch (err) {
        if (err.response?.status === 401) {
          window.location.href = "/unauthorized";
        } else if (err.response?.status === 404) {
          window.location.href = "/*";
        }
      }
    };

    infos();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [axiosPrivate, query]);

  return data;
};

export default useGetPracticesStats;
