import { useSearchParams } from "react-router-dom";
import Missing from "./Missing";
import {
  Container,
  Box,
  Divider,
  Typography,
  Grid,
  TextField,
  Button,
  Paper,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Copyright from "../../components/layout/Copyright";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import { useEffect, useState } from "react";
import { axiosAuth } from "../../api/axios";
import LoadingSpinner from "../../components/layout/LoadingSpinner";
import LogoPublic from "../../components/layout/LogoPublic";
import { isMobile } from "react-device-detect";

const PSW_MIN_LENGTH = 8;

const MakeNewPsw = () => {
  const [isActive, setIsActive] = useState(false);
  const [pswInput, setPswInput] = useState("");
  const [rePswInput, setRePswInput] = useState("");
  let [loading, setLoading] = useState(false);
  let [pswdsEqual, setPswdsEqual] = useState(false);
  const [searchParams] = useSearchParams();
  const otp = searchParams.get("key");
  const email = searchParams.get("usr");

  useEffect(() => {
	document.body.style.backgroundColor = "#005ca9";
  }, []);

  if (otp != null && email != null) {
    var psw = document.getElementById("password");
    var rePsw = document.getElementById("retyped-password");

    function showPswds() {
      if (psw?.type === "password" && rePsw?.type === "password") {
        setIsActive(true);
        psw.type = "text";
        rePsw.type = "text";
      } else if (psw.type === "text" && rePsw.type === "text") {
        setIsActive(false);
        psw.type = "password";
        rePsw.type = "password";
      }
    }

    function checkPswds() {
      if (psw?.value !== "" || rePsw?.value !== "") {
        if (
          psw?.value.length >= PSW_MIN_LENGTH ||
          rePsw?.value.length >= PSW_MIN_LENGTH
        ) {
          if (psw?.value !== rePsw?.value) {
            console.log("NOT OK");
            setPswdsEqual(false);
          } else {
            console.log("OK");
            setPswdsEqual(true);
          }
        }
      }
    }

    const handleSubmit = (event) => {
      setLoading(true);

      event.preventDefault();

      axiosAuth
        .post("/reset_password", {
          otp: otp,
          email: email,
          password: psw.value,
        })
        .then((response) => {
          window.alert("Password cambiata");
          window.location.href = "/";
        })
        .catch((error) => {
          setLoading(false);
          console.log("error: ", error);
          if (error.response?.status === 400) {
            window.alert(
              "Questo pagina risulta scaduta. Invia nuovamente una richiesta di reset password e completa la procedura entro 10 minuti dal ricevimento della email."
            );
            window.location.href = "/login/reset_password";
          } else {
            window.alert("Si è verificato un problema. Riprova.");
          }
        });
    };

    return (
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            pt: 5,
            pb: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box width={"100%"}>
            <LogoPublic />
          </Box>
          <Paper
            elevation={6}
            sx={{
              mt: 1,
              p: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: isMobile ? "80%" : "100%",
            }}
          >
            <Typography variant="h7">
              Cambierai la password per il tuo account all'indirizzo email che
              hai specificato ({email})
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Typography variant="h7">
              La password deve contenere minimo 8 caratteri.
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3, width: "100%" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    type="password"
                    id="password"
                    label="Password"
                    name="password"
                    autoComplete="password"
                    value={pswInput}
                    onChange={(e) => {
                      setPswInput(e.target.value);
                      checkPswds();
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    type="password"
                    id="retyped-password"
                    label="Inserisci nuovamente la password"
                    name="retyped-password"
                    autoComplete="retyped-password"
                    error={psw?.value !== rePsw?.value}
                    helperText={
                      psw?.value !== rePsw?.value &&
                      "Le password devono coincidere."
                    }
                    value={rePswInput}
                    onChange={(e) => {
                      setRePswInput(e.target.value);
                      checkPswds();
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button tabindex="-1" onClick={() => showPswds(!isActive)}>
                            {isActive ? (
                              <VisibilityOffIcon fontSize="large" />
                            ) : (
                              <VisibilityIcon fontSize="large" />
                            )}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              {pswdsEqual ? (
                loading ? (
                  <LoadingSpinner />
                ) : (
                  <Grid>
                    <Button
                      id="submitBtn"
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Invio
                    </Button>
                  </Grid>
                )
              ) : (
                <Grid>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled
                  >
                    Invio
                  </Button>
                </Grid>
              )}
              <Divider sx={{ my: 5 }} />
              <Typography variant="h7">
                ATTENZIONE: il link ricevuto per mail ha una validità di 10
                minuti, dopodichè occorre inoltrare una nuova richiesta di reset
                della password
              </Typography>
            </Box>
            <Copyright />
          </Paper>
        </Box>
      </Container>
    );
  } else return <Missing />;
};

export default MakeNewPsw;
