import React from "react";
import NavBar from "../../../components/navbar/NavBar";
import { Typography } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import PracticesStats from "../../../components/practices/PracticesStats";

const ControllerHome = () => {
  return (
    <>
      <NavBar type="8711" />
      <PracticesStats user="Controller" />
    </>
  );
};

export default ControllerHome;

