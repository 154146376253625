import React from 'react'
import NavBar from '../../../components/navbar/NavBar'
import PracticesStats from '../../../components/practices/PracticesStats'
import { Typography } from '@mui/material'
import { isMobile } from 'react-device-detect'

const CnaHome = () => {
  return (
    <>
      <NavBar type="5672" />
      <Typography variant="h5" color="primary" sx={{ mt: isMobile ? 12 : 3, mb: isMobile ? -10 : 1}}>
        Riepilogo pratiche sul portale
      </Typography>
      <PracticesStats user="Cna" />
    </>
  )
}

export default CnaHome