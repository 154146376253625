import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import LoadingSpinner from "../../layout/LoadingSpinner";

const PracticesNewFiles = ({
  handleSetValue,
  handleTabChange,
  handleCreatePractice,
  loading,
  filesList,
}) => {
  const [files, setFiles] = useState(
    filesList.map((file) => ({ ...file, id: Date.now() + Math.random() })) // Aggiunge un id univoco per ogni file
  );

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      alert("Puoi caricare massimo 25 files per pratica!");
      return;
    }

    setFiles((prevFiles) => [
      ...prevFiles,
      ...acceptedFiles.map((file) => ({
        id: Date.now() + Math.random(), // Aggiunge un id univoco
        name: file.name,
        size: file.size,
        preview: URL.createObjectURL(file),
        file,
      })),
    ]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: files.length === 25 || loading,
    accept: {
      "application/pdf": [],
      "application/msword": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel":
        [],
      "image/png": [],
      "image/jpeg": [],
    },
    maxFiles: 25,
  });

  const handleRemoveFile = (fileToRemove) => {
    setFiles((prevFiles) =>
      prevFiles.filter((file) => file.id !== fileToRemove.id)
    );
  };

  const handleGoBack = () => {
    handleSetValue(files, "files");
    handleTabChange("", 1);
  };

  const handleSubmit = () => {
    console.log(files)
    handleCreatePractice(files);
  };

  return (
    <>
      <Typography variant="h6" color="primary" sx={{ my: 2 }}>
        Inserisci tutta la documentazione e le foto utili all'apertura del
        sinistro (max. 25 files)
      </Typography>
      <Box
        {...getRootProps()}
        sx={{
          border: "2px solid #ddd",
          padding: 3,
          textAlign: "center",
          cursor: "pointer",
          borderRadius: 2,
          my: 2,
          "&:hover": {
            background: loading && "#e5f4ff",
          },
          background: loading && "#ddd",
        }}
      >
        <input {...getInputProps()} />
        <Typography
          variant="h6"
          sx={{ marginBottom: 2, color: loading && "grey" }}
        >
          {files.length >= 25 ? (
            <Typography variant="h6" color="error" sx={{ marginTop: 2 }}>
              Limite di 25 file raggiunto.
            </Typography>
          ) : isMobile ? (
            "Clicca qui per selezionare i files"
          ) : isDragActive ? (
            "Lascia qui i file"
          ) : (
            "Trascina qui i file oppure clicca per selezionarli"
          )}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Formati accettati: PDF, Word, Excel, Immagini.
          <br />
          Puoi caricare massimo 25 files.
        </Typography>
        {files.length > 0 && (
          <List
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "2%",
              rowGap: 1,
              justifyContent: "space-aroud",
              wordBreak: "break-all",
            }}
          >
            {files.map((file, index) => (
              <ListItem
                key={file.id}
                sx={{
                  borderRadius: 2,
                  width: isMobile ? "49%" : "32%",
                  boxShadow: 5,
                  background: "white",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: 1,
                }}
              >
                <Box sx={{ position: "absolute", top: 0, right: 0 }}>
                  <Tooltip title="Cancella" followCursor arrow>
                    <IconButton
                      disabled={loading}
                      size="small"
                      edge="end"
                      sx={{
                        color: "red",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveFile(file);
                      }}
                    >
                      <CloseIcon sx={{ fontSize: "25pt" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
                {file.file.type.startsWith("image/") ? (
                  <img
                    src={file.preview}
                    alt={file.name}
                    style={{
                      width: "100%",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: 8,
                      WebkitFilter:
                        loading && "grayscale(100%)" /* Safari 6.0 - 9.0 */,
                      filter: loading && "grayscale(100%)",
                    }}
                  />
                ) : (
                  <InsertDriveFileIcon
                    sx={{
                      fontSize: "100px",
                      color: loading ? "#ccc" : "#42a5f5",
                    }}
                  />
                )}
                <ListItemText
                  primary={file.name}
                  secondary={`${(file.size / 1024).toFixed(2)} KB`}
                  sx={{ textAlign: "center", color: loading && "grey" }}
                />
              </ListItem>
            ))}
          </List>
        )}
      </Box>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Button
            size="large"
            variant="contained"
            fullWidth
            onClick={() => handleSubmit()}
          >
            <DoneIcon /> APRI LA PRATICA {files.length === 0 && ("(inserisci file successivamente)")}
          </Button>
          <Button size="small" fullWidth color="error" onClick={handleGoBack}>
            <ArrowBackIcon /> INDIETRO
          </Button>
        </>
      )}
    </>
  );
};

export default PracticesNewFiles;
