import * as React from "react";

import { isMobile } from "react-device-detect";

import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Badge, Divider, IconButton, Link, Tooltip, Typography } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Box } from "@mui/system";
import useGetListOf from "../../../../hooks/useGetListOf";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useSearchParams } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";

const NotificationsMenu = () => {
  const axiosPrivate = useAxiosPrivate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const notificationsList = useGetListOf(
    "Notifications",
    "where_key=read&where_value=false&complete=true&order_key=created_at&order_desc=true",
    "list"
  );
  const notifications = notificationsList.listElements;
  const notificationsCount = notificationsList.totalElements;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAsRead = async (notificationCode) => {
    try {
      const response = await axiosPrivate.put("/Notifications/mark_as_read", {
        code: notificationCode,
      });
      if (response) {
        setSearchParams("");
      }
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      const response = await axiosPrivate.put(
        "/Notifications/mark_all_as_read"
      );
      if (response) {
        setSearchParams("");
      }
    } catch (err) {
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        fullWidth={isMobile || (window.innerWidth <= 785 && true)}
        sx={{
          color: "white",
          mt: 1,
          //   mb: isMobile && 1,
        }}
        // endIcon={
        //   <KeyboardArrowDownIcon sx={{ transform: open && "rotate(180deg)" }} />
        // }
      >
        <Badge badgeContent={notificationsCount} color="warning">
          <NotificationsIcon color="white" />
        </Badge>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          style: {
            color: "white",
            border: "1px solid white",
            borderRadius: 4,
            width: 300,
            height: 200,
          },
        }}
        sx={{ mt: 3 }}
      >
        <Box
          sx={{
            position: "fixed",
            zIndex: 10000,
            textAlign: "center",
            background: "#005ca9",
            mt: -1,
            borderRadius: 1,
            width: 298,
          }}
        >
          <Typography variant="subtitle2">
            Notifiche{" "}
            {notifications.length !== 0 && (
              <Link
                sx={{
                  height: 30,
                  ml: 8,
                  color: "white",
                  "&:hover": { cursor: "pointer" },
                }}
                onClick={handleMarkAllAsRead}
              >
                Segna tutte come lette
              </Link>
            )}
          </Typography>
        </Box>
        <Divider sx={{ mt: 2 }} />
        {notifications.length !== 0 ? (
          notifications.map((notification) => (
            <Box sx={{ display: "flex", borderBottom: "1px solid #ddd" }}>
              <MenuItem
                sx={{
                  background: "white",
                  color: "black",
                  width: "100%",
                  whiteSpace: "normal"
                }}
                onClick={() => {
                  handleMarkAsRead(notification["notifications.code"]);
                  setTimeout(
                    () =>
                      (window.location.href =
                        notification["notifications.url"]),
                    100
                  );
                }}
              >
                <Typography
                  variant="subtitle2"
                  color="primary"
                  sx={{ "&:hover": { textDecoration: "underline" } }}
                >
                  <b>{notification["messages.message"]}</b>
                </Typography>
              </MenuItem>
              <Tooltip title="Segna come letta" followCursor arrow>
                <IconButton
                fullWidth
                  color="success"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMarkAsRead(notification["notifications.code"]);
                  }}
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ))
        ) : (
          <Typography color="primary" sx={{ textAlign: "center" }}>
            Nessuna nuova notifica
          </Typography>
        )}
      </Menu>
    </>
  );
};

export default NotificationsMenu;
