import React, { useEffect } from "react";
import { useState } from "react";

import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";

import { axiosAuth } from "../../api/axios";
import useAuth from "../../hooks/auth/useAuth";
import Copyright from "../../components/layout/Copyright";
import LogoPublic from "../../components/layout/LogoPublic";
import useGetUserCompleteInfo from "../../hooks/users/useGetUserCompleteInfo";
import { Container, Grid2 } from "@mui/material";
import LoadingSpinner from "../../components/layout/LoadingSpinner";

export default function SignInSide() {
  // document.body.style = "background: #005ca9";
  // Or with CSS
  const { setAuth } = useAuth();
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const userRole = useGetUserCompleteInfo("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);

  // --------------------------- TODO cambiare chiave ------------------------------
  const RECAPTCHA_KEY = "6LegGkkqAAAAAGUDuLMvJFwVLHif0rfbFSZAUVLu";
  // -------------------------------------------------------------------------------

  useEffect(() => {
    document.body.style.backgroundColor = "#005ca9";
  }, []);

  if (userRole.user && userRole.user["roles.value"]) {
    switch (userRole.user["roles.value"]) {
      case "Admin":
        window.location.href = "/admin_home";
        break;

      case "Repairer":
        window.location.href = "/le_mie_pratiche";
        break;

      case "Intermediary":
        window.location.href = "/area_perito/le_mie_pratiche";
        break;

      case "Controller":
        window.location.href = "/area_controller/home";
        break;

      case "Cna":
        window.location.href = "/statistiche_pratiche";
        break;

      default:
        window.location.href = "/";
        break;
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    // if (!captchaValue) {
    //   setError("Per favore completa il CAPTCHA.");
    //   return;
    // }

    try {
      const response = await axiosAuth.post(
        "/login",
        JSON.stringify({ email: email, password: pwd }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      console.log(JSON.stringify(response?.data));
      const accessToken = response?.data?.result.accessToken;
      const role = response?.data?.result.role;
      console.log(accessToken);
      setAuth({ email, pwd, accessToken });
      setEmail("");
      setPwd("");
      switch (role) {
        case "9495":
          window.location.href = "/admin_home";
          break;

        case "8711":
          window.location.href = "/area_controller/home";
          break;

        case "2039":
          window.location.href = "/area_perito/le_mie_pratiche";
          break;

        case "6093":
          window.location.href = "/le_mie_pratiche";
          break;

        case "5672":
          window.location.href = "/statistiche_pratiche";
          break;

        default:
          window.location.href = "*";
          break;
      }
    } catch (err) {
      if (!err?.response) {
        window.alert(
          "Errore. Controlla che le credenziali inserite siano corrette"
        );
      } else if (err.response?.status === 400) {
        window.alert("Email o password non validi!");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Login fallito");
      }
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          pt: 5,
          pb: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box width={"100%"}>
          <LogoPublic />
        </Box>
        <Paper
          elevation={6}
          sx={{
            mt: 1,
            p: isMobile ? 2 : 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography component="h1" variant="h5">
            Accedi a Gestione Sinistro CNA
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1, width: "100%" }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={email}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              onChange={(e) => setPwd(e.target.value)}
              name="password"
              label="Password"
              type="password"
              id="password"
              value={pwd}
              autoComplete="current-password"
            />
            {/* <Box sx={{ m: 2, display: "flex", justifyContent: "center" }}>
              <ReCAPTCHA
                sitekey={RECAPTCHA_KEY}
                onChange={(value) => setCaptchaValue(value)}
              />
            </Box> */}
            {loading ? (
              <LoadingSpinner />
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
              >
                Accedi
              </Button>
            )}
          </Box>
          {error && <Typography color="error">{error}</Typography>}
          <Grid2 container>
            <Grid2 item sx={{ m: 1 }}>
              <Link href="login/reset_password" variant="body2">
                Password dimenticata?
              </Link>
            </Grid2>
            <Grid2 item sx={{ m: 1 }}>
              <Link href="/register" variant="body2">
                {"Non hai un account? Registrati qui"}
              </Link>
            </Grid2>
          </Grid2>
          <Copyright />
        </Paper>
      </Box>
    </Container>
  );
}
