import { Grid2 } from "@mui/material";
import { PulseLoader } from "react-spinners";

const LoadingSpinner = () => {
	return (
		<Grid2>
			<PulseLoader color="#005ca9" margin="30px" />
		</Grid2>
	);
};

export default LoadingSpinner;

		