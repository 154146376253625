import React from "react";
import NavBar from "../components/navbar/NavBar";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";

const Tables = ({roles}) => {

  const messagesCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Messages</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => window.location.href="../tables/comunication/messages"}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const message_typesCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Message_Types</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => window.location.href="../tables/comunication/message_types"}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const notificationsCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Notifications</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => window.location.href="../tables/comunication/notifications"}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const attachment_typesCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Attachment_Types</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => window.location.href="../tables/practice/attachment_types"}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const practicesCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Practices</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => window.location.href="../tables/practice/practices"}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const practice_additional_infosCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Practice_Additional_Infos</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => window.location.href="../tables/practice/practice_additional_infos"}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const practice_attachmentsCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Practice_Attachments</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => window.location.href="../tables/practice/practice_attachments"}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const practice_messagesCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Practice_Messages</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => window.location.href="../tables/practice/practice_messages"}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const practice_progressesCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Practice_Progresses</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => window.location.href="../tables/practice/practice_progresses"}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const practice_statesCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Practice_States</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => window.location.href="../tables/practice/practice_states"}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const practice_typesCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Practice_Types</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => window.location.href="../tables/practice/practice_types"}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const practice_usersCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Practice_Users</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => window.location.href="../tables/practice/practice_users"}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const rolesCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Roles</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => window.location.href="../tables/user/roles"}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const usersCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">Users</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => window.location.href="../tables/user/users"}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const user_anagraphicsCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">User_Anagraphics</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => window.location.href="../tables/user/user_anagraphics"}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );

  const user_verifiedCard = (
    <React.Fragment>
      <CardContent>
        <Typography variant="h5">User_Verified</Typography>
      </CardContent>
      <CardActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => window.location.href="../tables/user/user_verified"}
          >
            VAI
          </Button>
        </Box>
      </CardActions>
    </React.Fragment>
  );
  return (
    <>
      <NavBar roles={roles}/>
      <Typography variant="h5" sx={{ m: 5 }}>
        Seleziona una tabella su cui eseguire le operazioni
      </Typography>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        sx={{ mx: "auto" }} 
        justifyContent={"space-evenly"}
      >
        <Card sx={{ width: "24%", mb: 2 }} variant="outlined">
          {messagesCard}
        </Card>

        <Card sx={{ width: "24%", mb: 2 }} variant="outlined">
          {message_typesCard}
        </Card>

        <Card sx={{ width: "24%", mb: 2 }} variant="outlined">
          {notificationsCard}
        </Card>

        <Card sx={{ width: "24%", mb: 2 }} variant="outlined">
          {attachment_typesCard}
        </Card>

        <Card sx={{ width: "24%", mb: 2 }} variant="outlined">
          {practicesCard}
        </Card>

        <Card sx={{ width: "24%", mb: 2 }} variant="outlined">
          {practice_additional_infosCard}
        </Card>

        <Card sx={{ width: "24%", mb: 2 }} variant="outlined">
          {practice_attachmentsCard}
        </Card>

        <Card sx={{ width: "24%", mb: 2 }} variant="outlined">
          {practice_messagesCard}
        </Card>

        <Card sx={{ width: "24%", mb: 2 }} variant="outlined">
          {practice_progressesCard}
        </Card>

        <Card sx={{ width: "24%", mb: 2 }} variant="outlined">
          {practice_statesCard}
        </Card>

        <Card sx={{ width: "24%", mb: 2 }} variant="outlined">
          {practice_typesCard}
        </Card>

        <Card sx={{ width: "24%", mb: 2 }} variant="outlined">
          {practice_usersCard}
        </Card>

        <Card sx={{ width: "24%", mb: 2 }} variant="outlined">
          {rolesCard}
        </Card>

        <Card sx={{ width: "24%", mb: 2 }} variant="outlined">
          {usersCard}
        </Card>

        <Card sx={{ width: "24%", mb: 2 }} variant="outlined">
          {user_anagraphicsCard}
        </Card>

        <Card sx={{ width: "24%", mb: 2 }} variant="outlined">
          {user_verifiedCard}
        </Card>
 {/* ancora per cards */}
      </Box>
    </>
  );
};

export default Tables;