import { useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { handleCreateErrors } from "../../../components/utils/errors"

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useGetUnlimitedListOf from "../../../hooks/useGetUnlimitedListOf";


const Create = ({ handleOpen, open }) => {
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();

  const table = "Practice_Attachments";

  const [selectedpractice_code, setSelectedpractice_code] = useState(null);
  const [selecteduser_code, setSelecteduser_code] = useState(null);
  const [selectedattachment_type_id, setSelectedattachment_type_id] = useState(null);
  const practicesTemp = useGetUnlimitedListOf("Practices", "", "list");
  const practicesData = practicesTemp.listElements;

  const usersTemp = useGetUnlimitedListOf("Users", "", "list");
  const usersData = usersTemp.listElements;

  const attachment_typesTemp = useGetUnlimitedListOf("Attachment_Types", "", "list");
  const attachment_typesData = attachment_typesTemp.listElements;

  const columns = ["id", "practice_code", "user_code", "attachment_type_id", "path", "filename"];

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const create = async (formData) => {
if (!formData.practice_code?.trim() || !formData.user_code?.trim() || formData.attachment_type_id === null || !formData.path?.trim() || !formData.filename?.trim()) {
    window.alert("practice_code, user_code, attachment_type_id, path, filename non possono essere vuoti!");
    return;
  }


	 const errors = {}
	 const apiCall = `/${table}/create`
    try {
      const response = await axiosPrivate.post(apiCall, formData);
      if (!response) throw new Error("Errore nella creazione");

      window.alert("Creazione avvenuta con successo")

    } catch (error) {
    errors.call = apiCall;
    errors.callData = formData;
    errors.error = error;
    errors.forceReload = false;

    handleCreateErrors(errors, true)

    }
  };

  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
          }}
          alignItems={"center"}
        >
          <Box display={"flex"} justifyContent={"space-between"} sx={{ mb: 2 }}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Crea un nuovo elemento per la tabella {table}
            </Typography>
            <Button
              color="error"
              onClick={() => handleOpen(false, "create")}
              sx={{
                maxWidth: "30px",
                maxHeight: "30px",
                minWidth: "30px",
                minHeight: "30px",
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{ width: "100%" }}
          >
            {/* Autocomplete per il campo practice_code */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={practicesData}
              getOptionLabel={(option) => option.title || option.code}
              value={
                selectedpractice_code
                  ? practicesData.find((practice) => practice.code === selectedpractice_code)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedpractice_code(newValue.code);
                  setValue("practice_code", newValue.code);
                }
              }}
              isOptionEqualToValue={(option, value) =>
                option.code === value.code
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select practice_code"
                  placeholder="practice_code"
                  error={!!errors.practice_code}
                  helperText={
                    errors.practice_code ? "Il campo practice_code è obbligatorio" : ""
                  }
                />
              )}
            />
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{ width: "100%" }}
          >
            {/* Autocomplete per il campo user_code */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={usersData}
              getOptionLabel={(option) => option.email || option.code}
              value={
                selecteduser_code
                  ? usersData.find((user) => user.code === selecteduser_code)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelecteduser_code(newValue.code);
                  setValue("user_code", newValue.code);
                }
              }}
              isOptionEqualToValue={(option, value) =>
                option.code === value.code
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select user_code"
                  placeholder="user_code"
                  error={!!errors.user_code}
                  helperText={
                    errors.user_code ? "Il campo user_code è obbligatorio" : ""
                  }
                />
              )}
            />
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{ width: "100%" }}
          >
            {/* Autocomplete per il campo attachment_type_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={attachment_typesData}
              getOptionLabel={(option) => option.value || option.id}
              value={
                selectedattachment_type_id
                  ? attachment_typesData.find((practice) => practice.id === selectedattachment_type_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedattachment_type_id(newValue.id);
                  setValue("attachment_type_id", newValue.id);
                }
              }}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select attachment_type_id"
                  placeholder="attachment_type_id"
                  error={!!errors.attachment_type_id}
                  helperText={
                    errors.attachment_type_id ? "Il campo attachment_type_id è obbligatorio" : ""
                  }
                />
              )}
            />
          </Box>

          {/* Altri campi */}
          {columns
            .filter((column) => column !=="id" && column !== "practice_code" && column !== "user_code" && column !== "attachment_type_id")
            .map((column, index) => (
              <TextField
                key={index}
                placeholder={column}
                sx={{ width: "50%", m: 1 }}
                {...register(column)}
              />
            ))}


          <Button onClick={handleSubmit(create)}>CREA ENTRY</Button>
        </Box>
      </Modal>
    </>
  );
};

export default Create;