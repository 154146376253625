import React from "react";
import { isMobile } from "react-device-detect";
import { Button } from "@mui/material";
import useLogout from "../../../../hooks/auth/useLogout";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

const LogoutBtn = () => {
  const logout = useLogout();
  const signOut = async () => {
    if (window.confirm("Verrai disconnesso, procedere?")) await logout();
  };
  return (
    <Button
      size="small"
      variant={isMobile ? "text" : "contained"}
      color="error"
      endIcon={isMobile && <ExitToAppIcon />}
      fullWidth={(isMobile || window.innerWidth <= 785) && true}
      onClick={signOut}
      sx={{ mt: isMobile && 3, borderBottom: isMobile && "1px solid #ddd", mb: isMobile && 1 }}
    >
      {isMobile ? "ESCI" : <ExitToAppIcon />}
    </Button>
  );
};

export default LogoutBtn;
