import React from 'react'
import NavBar from '../../../../components/navbar/NavBar'
import UsersDetail from '../../../../components/users/UsersDetail'
import { useParams } from 'react-router-dom'

const CnaUsersDetail = ({ type }) => {
    const { codeUser } = useParams();
  return (
    <>
    <NavBar type="5672" />
    <UsersDetail user="Cna" userCode={codeUser} userType={type}/>
  </>
  )
}

export default CnaUsersDetail