import React, { useEffect, useState } from "react";

import { isMobile } from "react-device-detect";
import { useNavigate, useSearchParams } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import { itIT } from "@mui/x-data-grid/locales";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import LastPageIcon from "@mui/icons-material/LastPage";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ClickAwayListener,
  Container,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TableContainer,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

// import rolesList from "./roles/RolesList";
import usersList from "./users/UsersList";
import useGetListOf from "../hooks/useGetListOf";
// import promotionsList from "./promotions/PromotionsList";
import useCollectSearchParams from "../hooks/useCollectSearchParams";
import practicesList from "./practices/PracticesList";
import PracticeStatusListFilter from "./practices/PracticeStatusListFilter";
// import promotionAssignmentsList from "./promotions/PromotionAssignmentsList";
// import PromotionsTypeListFilter from "./promotions/PromotionsTypeListFilter";
// import PromotionsStatusListFilter from "./promotions/PromotionsStatusListFilter";

const ElementsList = (list) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [defaultSearchKeys, setDefaultSearchKeys] = useState(
    list.variantSearchKeys
  );
  const [expandFilters, setExpandFilters] = useState(false);
  const [openElemPerPageFilter, setOpenElemPerPageFilter] = useState(false);

  // useEffect(() => {
  //   if (list.variantSearchKeys) {
  //     setDefaultSearchKeys(list.variantSearchKeys);
  //   }
  // }, [list.variantSearchKeys]);

  let listType = list.type;

  if (list.type === "clients") {
    // perché altrimenti mi fa la chiamata "listType"/list
    listType = "";
  }

  let defaultList = "list";

  if (list.variant === "inactive") {
    defaultList = "list_without_active";
  }

  let elementsList = useGetListOf(listType, defaultSearchKeys, defaultList);

  const elements = elementsList.listElements;
  let totalPages = elementsList.totalPages;
  let totalElements = elementsList.totalElements;

  const params = useCollectSearchParams();
  let specifiedParamsNumber = params.specifiedParamsNumber;
  let frontendParams = params.frontendParams;
  const [multipleParams, setMultipleParams] = useState({ ...frontendParams });
  // setMultipleParams(frontendParams);

  let table = {};
  let rows = [];
  let columns = [];

  if (frontendParams.page > totalPages) {
    frontendParams.page = 1;
  }

  switch (list.type) {
    case "Practices":
      table = practicesList(elements, list.user, frontendParams);
      rows = table.rows;
      columns = table.columns;
      break;

    // case "Promotion_Assignments":
    //   table = promotionAssignmentsList(elements, list.user, frontendParams);
    //   rows = table.rows;
    //   columns = table.columns;
    //   break;

    // case "Roles":
    //   table = rolesList(
    //     elements,
    //     list.user,
    //     frontendParams,
    //     list.variant === "inactive" ? false : true
    //   );
    //   rows = table.rows;
    //   columns = table.columns;
    //   break;

    case "Users":
      table = usersList(elements, list.user, frontendParams, list.variant);
      rows = table.rows;
      columns = table.columns;
      break;

    default:
      break;
  }

  const handleElementsPerPageChange = (event) => {
    frontendParams = {
      ...frontendParams,
      limit: parseInt(event.target.value),
      page: 1,
    };
    setSearchParams(frontendParams);
    setOpenElemPerPageFilter(false);
    setExpandFilters(false);
  };

  const handlePracticeStatusFilter = (value, type) => {
    if (value.length === 0) {
      setDefaultSearchKeys(list.variantSearchKeys);
      setSearchParams("");
    } else {
      const updatedParams = { ...multipleParams };

      const currentParams = new URLSearchParams(defaultSearchKeys);
      const whereKey = currentParams.get("where_key") || "";
      const whereValue = currentParams.get("where_value") || "";

      const existingKeys = whereKey.split("|");
      const existingValues = whereValue.split("|");

      const keyIndex = existingKeys.indexOf(type);
      if (keyIndex !== -1) {
        existingValues[keyIndex] = value;
      } else {
        existingKeys.push(type);
        existingValues.push(value);
      }

      updatedParams.where_key = existingKeys.join("|");
      updatedParams.where_value = existingValues.join("|");
      updatedParams.complete = true;

      setDefaultSearchKeys(
        `where_key=${updatedParams.where_key}&where_value=${updatedParams.where_value}`
      );
      setMultipleParams(updatedParams);

      // setSearchParams(updatedParams); //errore principale qui
      // setSearchParams(list.variantSearchKeys); //boh senza va uguale e con si rompe solo su dettaglio utente ?????
      setExpandFilters(false);
    }
  };

  return (
    <React.Fragment>
      <Container component={Paper} sx={{ borderRadius: 3 }}>
        <TableContainer>
          {(typeof list.variant === "undefined" && list.variant == null) ||
          list.variant === "practice_assignment" ? (
            <React.Fragment>
              <Box
                sx={{
                  display: !isMobile && "flex",
                  alignContent: "left",
                  width: "100%",
                  mt: 3,
                }}
              >
                {(list.user === "Admin" || list.user === "Repairer") &&
                list.variant !== "inactive" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    sx={{ width: isMobile ? "100%" : "25%", mb: isMobile && 3 }}
                    onClick={() => {
                      if (list.type === "Practices") {
                        navigate("/nuova_pratica");
                      } else if (list.type === "Users") {
                        navigate(
                          "/alphega_centrale/alphega_users/alphega_create_user"
                        );
                      } else if (list.type === "clients") {
                        navigate("/broker/clienti/nuovo_cliente");
                      } else if (list.type === "Surveys") {
                        navigate(`/${list.user}/questionari/nuovo`);
                      }
                    }}
                  >
                    {list.type === "Practices" ? "Nuova pratica" : "Nuovo"}
                  </Button>
                ) : (
                  <Box sx={{ ml: -2 }} />
                )}
                <TextField
                  label="Cerca"
                  id="searchbar"
                  defaultValue={frontendParams.search_value}
                  sx={{ width: "100%", ml: !isMobile && 2 }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      if (list.type === "Practices") {
                        frontendParams = {
                          ...frontendParams,
                          search_key: "title",
                          search_value: event.target.value,
                        };
                      } else if (list.type === "Users") {
                        frontendParams = {
                          ...frontendParams,
                          search_key: "business_name",
                          search_value: event.target.value,
                        };
                      } else if (
                        list.type === "Product_Clauses" &&
                        list.variant === "ProductClausesToValidate"
                      ) {
                        frontendParams = {
                          ...frontendParams,
                          search_key: "product_clause_title",
                          search_value: event.target.value,
                        };
                      } else if (
                        list.type === "Survey_Maps" &&
                        list.variant === "SurveyMapDetail"
                      ) {
                        frontendParams = {
                          ...frontendParams,
                          search_key: "question_text",
                          search_value: event.target.value,
                        };
                      } else {
                        frontendParams = {
                          ...frontendParams,
                          search_key: "name",
                          search_value: event.target.value,
                        };
                      }
                      setSearchParams(frontendParams);
                      // navigate(0);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {document.getElementById("searchbar")?.value !== "" && (
                          <Button
                            color="error"
                            onClick={(event) => {
                              delete frontendParams["search_key"];
                              delete frontendParams["search_value"];
                              // frontendParams = {
                              //   ...frontendParams,
                              //   search_key: "",
                              //   search_value: "",
                              // };
                              setSearchParams(frontendParams);
                              document.getElementById("searchbar").value = "";
                              // navigate(0);
                            }}
                            sx={{ minWidth: 0.1 }}
                          >
                            <CloseIcon />
                          </Button>
                        )}
                        <Button
                          onClick={(event) => {
                            let value =
                              document.getElementById("searchbar").value;
                            if (list.type === "Practices") {
                              frontendParams = {
                                ...frontendParams,
                                search_key: "title",
                                search_value: value,
                              };
                            } else if (list.type === "Users") {
                              frontendParams = {
                                ...frontendParams,
                                search_key: "business_name",
                                search_value: value,
                              };
                            } else if (
                              list.type === "Product_Clauses" &&
                              list.variant === "ProductClausesToValidate"
                            ) {
                              frontendParams = {
                                ...frontendParams,
                                search_key: "product_clause_title",
                                search_value: value,
                              };
                            } else if (
                              list.type === "Survey_Maps" &&
                              list.variant === "SurveyMapDetail"
                            ) {
                              frontendParams = {
                                ...frontendParams,
                                search_key: "question_text",
                                search_value: value,
                              };
                            } else {
                              frontendParams = {
                                ...frontendParams,
                                search_key: "name",
                                search_value: value,
                              };
                            }
                            setSearchParams(frontendParams);
                            // navigate(0);
                          }}
                          sx={{ minWidth: 0.1 }}
                        >
                          <SearchIcon />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "100%",
                  mt: 1,
                  mb: 5,
                }}
              >
                {list.type === "Users" && list.user === "alphega_centrale" && (
                  <Tooltip
                    title={`Mostra gli utenti con l'accesso al portale ${
                      list.variant === "inactive" ? "abilitato" : "disabilitato"
                    }`}
                    followCursor
                    arrow
                  >
                    <Button
                      variant={
                        list.variant === "inactive" ? "contained" : "outlined"
                      }
                      onClick={() => {
                        if (list.variant === "inactive")
                          navigate("/alphega_centrale/alphega_users");
                        else
                          navigate(
                            "/alphega_centrale/alphega_users/deactivated"
                          );
                      }}
                      sx={{ mb: 1 }}
                    >
                      {list.variant === "inactive"
                        ? "Mostra attivi"
                        : "Mostra non attivi"}
                    </Button>
                  </Tooltip>
                )}
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                >
                  <ClickAwayListener
                    onClickAway={() => setExpandFilters(false)}
                  >
                    <Accordion
                      expanded={expandFilters}
                      onChange={() => setExpandFilters(!expandFilters)}
                      sx={{
                        background: "#005ca9",
                        border: "5px solid #005ca9",
                        borderBottomRightRadius: 15,
                        borderBottomLeftRadius: 15,
                        width: "100%",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{ color: "white", fontSize: "30pt" }}
                          />
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        color="primary"
                        sx={{ my: -1.5 }}
                      >
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          width={"100%"}
                        >
                          <Typography variant="h6" fontSize={14} color="white">
                            Mostra filtri
                          </Typography>
                          <Button
                            variant="contained"
                            color="error"
                            sx={{ height: 20 }}
                            size="small"
                            startIcon={<DeleteIcon />}
                            onClick={(e) => {
                              e.stopPropagation();
                              setSearchParams("");
                              if (list.type === "Practices") {
                                setDefaultSearchKeys(list.variantSearchKeys);
                                setMultipleParams("");
                              }
                            }}
                            disabled={
                              specifiedParamsNumber !== 0 ? false : true
                            }
                          >
                            Reset filtri
                          </Button>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          background: "white",
                          borderRadius: 3,
                          zIndex: 100,
                          border: "5px solid #005ca9",
                          mt: -0.5,
                        }}
                      >
                        <Box sx={{ mb: 1 }}>
                          {list.type === "Practices" && (
                            <PracticeStatusListFilter
                              onTypeSet={handlePracticeStatusFilter}
                              searchParams={searchParams}
                              user={list.user}
                            />
                          )}
                        </Box>
                        {/* <Box sx={{ mb: 2 }}>
                          {(list.type === "Promotions" ||
                            list.type === "Promotion_Assignments") && (
                            <PromotionsTypeListFilter
                              onTypeSet={handlePromotionsFilter}
                              searchParams={searchParams}
                            />
                          )}
                        </Box> */}
                        <FormControl sx={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">
                            Elementi per pagina
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={frontendParams.limit}
                            label="Elementi per pagina"
                            onChange={handleElementsPerPageChange}
                            size="small"
                            open={openElemPerPageFilter}
                            onClick={() =>
                              setOpenElemPerPageFilter(!openElemPerPageFilter)
                            }
                          >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={75}>75</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                          </Select>
                        </FormControl>
                      </AccordionDetails>
                    </Accordion>
                  </ClickAwayListener>
                </Box>
              </Box>
            </React.Fragment>
          ) : (
            list.variant !== "CompleteUserDetail" && (
              <Divider sx={{ border: 0, my: 11.5 }} />
            )
          )}
          <Box sx={{ width: "100%" }}>
            <Box sx={{ mt: -3, mb: -2, textAlign: "left" }}>
              Risultato: {totalElements} elementi
            </Box>
            <br />
            <DataGrid
              hideFooterPagination
              hideFooterSelectedRowCount
              hideFooter
              autosize
              onColumnHeaderClick={(field) => {
                if (
                  field.field !== "action" &&
                  list.type !== "Survey_Map_Links"
                ) {
                  if (frontendParams.order_desc === "false") {
                    frontendParams = {
                      ...frontendParams,
                      order_key: field.field,
                      order_desc: "true",
                    };
                    setSearchParams(frontendParams);
                  } else {
                    frontendParams = {
                      ...frontendParams,
                      order_key: field.field,
                      order_desc: "false",
                    };
                    setSearchParams(frontendParams);
                  }
                }
              }}
              disableColumnMenu
              rows={rows}
              columns={columns}
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              rowHeight={65}
              autoHeight={rows.length === 0 ? true : false} //se nessun elemento non collassa totalmente la tabella, se invece != 0 fa apparire scrollbar se arriva a maxHeight
              // sx={{ maxHeight: 1000, textAlign: "left" }}
              slotProps={{ toolbar: { id: 1 } }}
              slots={{ toolbar: GridToolbar }}
              disableColumnFilter
              disableDensitySelector
              // getRowHeight={() => "auto"}
              sx={{
                maxHeight: 1000,
                textAlign: "left",
                [`& .MuiDataGrid-cell`]: {
                  // paddingTop: 1.5,
                  // paddingBottom: 1.5,
                  lineHeight: "unset !important",
                  maxHeight: "none !important",
                  whiteSpace: "normal",
                },
                // [`& .MuiDataGrid-columnHeader`]: {
                //   maxHeight: "none !important",
                //   height: "auto !important",
                //   whiteSpace: "inherit !important",
                //   overflow: "inherit !important",
                //   lineHeight: "24px !important",
                // },
                [`& .MuiDataGrid-columnHeaderTitle`]: {
                  whiteSpace: "normal !important",
                },
              }}
            />
            <br />
            {parseInt(frontendParams.page) !== 1 ? (
              <React.Fragment>
                <Button
                  onClick={() => {
                    frontendParams = {
                      ...frontendParams,
                      page: 1,
                    };
                    setSearchParams(frontendParams);
                  }}
                  size="small"
                >
                  <FirstPageIcon />
                </Button>
                <Button
                  onClick={() => {
                    frontendParams = {
                      ...frontendParams,
                      page: parseInt(frontendParams.page) - 1,
                    };
                    setSearchParams(frontendParams);
                  }}
                  size="small"
                >
                  <NavigateBeforeIcon />
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button size="small" disabled>
                  <FirstPageIcon />
                </Button>
                <Button size="small" disabled>
                  <NavigateBeforeIcon />
                </Button>
              </React.Fragment>
            )}
            {parseInt(frontendParams.page) === totalPages &&
              parseInt(frontendParams.page) > 2 && (
                <Button
                  onClick={() => {
                    frontendParams = {
                      ...frontendParams,
                      page: parseInt(frontendParams.page) - 2,
                    };
                    setSearchParams(frontendParams);
                  }}
                  variant="outlined"
                  size="small"
                >
                  {parseInt(frontendParams.page) - 2}
                </Button>
              )}
            {parseInt(frontendParams.page) > 1 && (
              <Button
                onClick={() => {
                  frontendParams = {
                    ...frontendParams,
                    page: parseInt(frontendParams.page) - 1,
                  };
                  setSearchParams(frontendParams);
                }}
                variant="outlined"
                size="small"
              >
                {parseInt(frontendParams.page) - 1}
              </Button>
            )}
            <Button variant="contained">{frontendParams.page}</Button>
            {parseInt(frontendParams.page) < totalPages && (
              <Button
                onClick={() => {
                  frontendParams = {
                    ...frontendParams,
                    page: parseInt(frontendParams.page) + 1,
                  };
                  setSearchParams(frontendParams);
                }}
                variant="outlined"
                size="small"
              >
                {parseInt(frontendParams.page) + 1}
              </Button>
            )}
            {parseInt(frontendParams.page) === 1 &&
              parseInt(frontendParams.page) !== totalPages &&
              totalPages !== parseInt(frontendParams.page) + 1 &&
              totalPages !== 0 && (
                <Button
                  onClick={() => {
                    frontendParams = {
                      ...frontendParams,
                      page: parseInt(frontendParams.page) + 2,
                    };
                    setSearchParams(frontendParams);
                  }}
                  variant="outlined"
                  size="small"
                >
                  {parseInt(frontendParams.page) + 2}
                </Button>
              )}
            {parseInt(frontendParams.page) !== totalPages &&
            totalPages !== 0 ? (
              <React.Fragment>
                <Button
                  onClick={() => {
                    frontendParams = {
                      ...frontendParams,
                      page: parseInt(frontendParams.page) + 1,
                    };
                    setSearchParams(frontendParams);
                  }}
                  size="small"
                >
                  <NavigateNextIcon />
                </Button>
                <Button
                  onClick={() => {
                    frontendParams = {
                      ...frontendParams,
                      page: totalPages,
                    };
                    setSearchParams(frontendParams);
                  }}
                  size="small"
                >
                  <LastPageIcon />
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button size="small" disabled>
                  <NavigateNextIcon />
                </Button>
                <Button size="small" disabled>
                  <LastPageIcon />
                </Button>
              </React.Fragment>
            )}
          </Box>
        </TableContainer>
        <br />
      </Container>
    </React.Fragment>
  );
};

export default ElementsList;
