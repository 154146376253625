import logo from "../../logo.svg";
import { Link } from "react-router-dom";
import { Box, Link as LinkLogo } from "@mui/material";

const HOMEPAGE = "/";

const LogoPublic = () => {
	return (
		<LinkLogo
			component={Link}
			to={HOMEPAGE}
			sx={{ mb: 1, width: "100%", height: "100%" }}
		>
			<Box component="img" src={logo} sx={{ width: "50%"}} />
		</LinkLogo>
	);
};

export default LogoPublic;
		