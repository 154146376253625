import { createTheme } from "@mui/material/styles";
import { fontWeight } from "@mui/system";

const theme = createTheme({
  typography: {
    fontFamily: "Inter, sans-serif",
    h1: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "bold",
    },
    h2: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "bold",
    },
    h3: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "bold",
    },
    h4: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "bold",
    },
    h5: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "bold",
    },
    h6: {
      fontFamily: "Inter, sans-serif",
      fontWeight: "bold",
    },
  },
  palette: {
    primary: {
      main: "#005ca9",
      light: "#13a538",
    },
    background: {
      default: "#f4f6f8", // Colore di sfondo predefinito
      paper: "#ffffff", // Colore di sfondo dei componenti Paper
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Inter, sans-serif",
          fontWeight: "bold",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
        columnHeader: {
        backgroundColor: "#005ca9",
          color: "white",
          borderTop: "1px solid #ddd",
        },
        columnHeaderTitle: {
          fontWeight: "bold",
        },
      },
    },
    // MuiTypography: {
    //   styleOverrides: {
    //     root: {
    //       fontFamily: "Inter, sans-serif",
    //       fontWeight: "bold",
    //     },
    //   },
    // },
  },
});
console.log("Tema applicato:", theme);

export default theme;
