import axios from "axios";
// const BASE_URL = 'https://localhost:8888/';
const BASE_URL = 'https://gestionesinistro.cna.it/api/';

export default axios.create({
	baseURL: BASE_URL,
});

export const axiosAuth = axios.create({
	baseURL: BASE_URL+'auth'
});

export const axiosPrivate =  axios.create({
	baseURL: BASE_URL,
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true,
	secure: true
});
	