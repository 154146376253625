import { Button } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";

const NewPracticeBtn = () => {
  return (
    <Button
    size="large"
      fullWidth={(isMobile || window.innerWidth <= 785) && true}
      onClick={() => (window.location.href = "/nuova_pratica")}
      sx={{ color: !isMobile && "gold", borderBottom: isMobile && "1px solid #ddd",mb: isMobile && 1 }}
    >
      NUOVA PRATICA
    </Button>
  );
};

export default NewPracticeBtn;
