import { useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { handleCreateErrors } from "../../../components/utils/errors"

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useGetUnlimitedListOf from "../../../hooks/useGetUnlimitedListOf";


const Create = ({ handleOpen, open }) => {
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();

  const table = "Users";

  const [selectedrole_code, setSelectedrole_code] = useState(null);
  const rolesTemp = useGetUnlimitedListOf("Roles", "", "list");
  const rolesData = rolesTemp.listElements;

  const columns = ["code", "role_code", "email", "password", "active_state"];

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const create = async (formData) => {
if (!formData.role_code?.trim() || !formData.email?.trim()) {
    window.alert("role_code, email non possono essere vuoti!");
    return;
  }


	 const errors = {}
	 const apiCall = `/${table}/create`
    try {
      const response = await axiosPrivate.post(apiCall, formData);
      if (!response) throw new Error("Errore nella creazione");

      window.alert("Creazione avvenuta con successo")

    } catch (error) {
    errors.call = apiCall;
    errors.callData = formData;
    errors.error = error;
    errors.forceReload = false;

    handleCreateErrors(errors, true)

    }
  };

  return (
    <>
      <Modal open={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
          }}
          alignItems={"center"}
        >
          <Box display={"flex"} justifyContent={"space-between"} sx={{ mb: 2 }}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Crea un nuovo elemento per la tabella {table}
            </Typography>
            <Button
              color="error"
              onClick={() => handleOpen(false, "create")}
              sx={{
                maxWidth: "30px",
                maxHeight: "30px",
                minWidth: "30px",
                minHeight: "30px",
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{ width: "100%" }}
          >
            {/* Autocomplete per il campo role_code */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={rolesData}
              getOptionLabel={(option) => option.value || option.code}
              value={
                selectedrole_code
                  ? rolesData.find((user) => user.code === selectedrole_code)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedrole_code(newValue.code);
                  setValue("role_code", newValue.code);
                }
              }}
              isOptionEqualToValue={(option, value) =>
                option.code === value.code
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select role_code"
                  placeholder="role_code"
                  error={!!errors.role_code}
                  helperText={
                    errors.role_code ? "Il campo role_code è obbligatorio" : ""
                  }
                />
              )}
            />
          </Box>

          {/* Altri campi */}
          {columns
            .filter((column) => column !=="code" && column !== "role_code" && column !== "active_state")
            .map((column, index) => (
              <TextField
                key={index}
                placeholder={column}
                sx={{ width: "50%", m: 1 }}
                {...register(column)}
              />
            ))}


          <Button onClick={handleSubmit(create)}>CREA ENTRY</Button>
        </Box>
      </Modal>
    </>
  );
};

export default Create;