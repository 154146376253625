import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import useGetUserCompleteInfo from "../../hooks/users/useGetUserCompleteInfo";

const PracticesDetailRepairerInfoModal = ({ open, onClose, userCode }) => {
  console.log(userCode);
  const userData = useGetUserCompleteInfo(userCode);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  if (userData.length !== 0)
    return (
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <AppBar sx={{ position: "relative", overflow: "hidden" }}>
          <Toolbar>
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
              {userData.user_anagraphic.business_name}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            p: 2,
            height: 500,
            overflow: "auto",
            background: "#f9f9f9",
            borderRadius: 2,
          }}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ fontWeight: "bold", width: "40%" }}>
                  Ragione Sociale
                </TableCell>
                <TableCell>{userData.user_anagraphic.business_name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>
                  Partita IVA
                </TableCell>
                <TableCell>{userData.user_anagraphic.p_iva}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Indirizzo</TableCell>
                <TableCell>{userData.user_anagraphic.address}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Nome</TableCell>
                <TableCell>{userData.user_anagraphic.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Cognome</TableCell>
                <TableCell>{userData.user_anagraphic.surname}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>
                  Indirizzo Mail
                </TableCell>
                <TableCell>{userData.user.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Telefono</TableCell>
                <TableCell>{userData.user_anagraphic.telephone}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Dialog>
    );
};

export default PracticesDetailRepairerInfoModal;
