import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import useAxiosPrivate from "./useAxiosPrivate";
import useCollectSearchParams from "./useCollectSearchParams";

const useGetListOf = (listType, backendParams, defaultList) => {
	const axiosPrivate = useAxiosPrivate();
	const navigate = useNavigate();
	const location = useLocation();
	const [totalPages, setTotalPages] = useState(0);
	const [listElements, setListElements] = useState([]);
	const [totalElements, setTotalElements] = useState(0);

	const searchParams = useCollectSearchParams();
	const frontendParams = searchParams.frontendParams;

	// if(!backendParams){
	if (
		!frontendParams.page ||
		parseInt(frontendParams.page) === 0 ||
		totalElements <= frontendParams.limit ||
		totalPages < frontendParams.page
	) {
		backendParams = backendParams + "&page=0";
	} else {
		backendParams =
			backendParams + `&page=${parseInt(frontendParams.page) - 1}`; // page-1 perch� il backend parte da 0 e frontend da 1
	}

	if (!frontendParams.limit) {
		frontendParams.limit = 20;
	} else {
		backendParams = backendParams + `&limit=${frontendParams.limit}`;
	}

	if (frontendParams.complete) {
		backendParams = backendParams + `&complete=${frontendParams.complete}`;
	}

	if (frontendParams.where_key) {
		backendParams = backendParams + `&where_key=${frontendParams.where_key}`;
	}

	if (frontendParams.where_value) {
		backendParams =
			backendParams + `&where_value=${frontendParams.where_value}`;
	}

	if (frontendParams.search_key) {
		backendParams = backendParams + `&search_key=${frontendParams.search_key}`;
	}

	if (frontendParams.search_value) {
		backendParams =
			backendParams + `&search_value=${frontendParams.search_value}`;
	}

	if (frontendParams.order_key) {
		backendParams = backendParams + `&order_key=${frontendParams.order_key}`;
	}

	if (frontendParams.order_desc) {
		backendParams = backendParams + `&order_desc=${frontendParams.order_desc}`;
	}
	// }

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();

		const list = async () => {
			if (listType !== "") {
				// se non � specificato non faccio la chiamata
				try {
					const response = await axiosPrivate.get(
						`/${listType}/${defaultList}?${backendParams}`
					);
					if (isMounted) {
						setListElements(response.data?.result.list_element); // && => se isMounted == true
						setTotalElements(response.data?.result.total_element);

						let pageNumber = totalElements / frontendParams.limit;

						if (Math.floor(pageNumber) === pageNumber)
							setTotalPages(pageNumber);
						else setTotalPages(Math.floor(pageNumber) + 1);
					}
				} catch (err) {
					if (err.response?.status === 401) {
						window.location.href = "/unauthorized";
					} else {
						navigate("/login", { state: { from: location }, replace: true });
					}
				}
			}
		};

		list();

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, [
		axiosPrivate,
		backendParams,
		listType,
		location,
		navigate,
		totalElements,
		totalPages,
		frontendParams.page,
		frontendParams.limit,
		defaultList
	]);

	return {
		listElements: listElements,
		totalPages: totalPages,
		totalElements: totalElements,
	};
};

export default useGetListOf;	
	