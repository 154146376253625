import { useLocation } from "react-router-dom";

import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const Delete = ({id}) => {
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();

  const table = "Practice_Users";

  const del = async () => {
    try {
      const response = await axiosPrivate.delete(
        `/${table}/delete?=${id}`
      );
      if (response) {
        window.alert("Eliminazione avvenuta con sucesso!");
        window.location.reload();
      }
    } catch (err) {
      if (err.response?.status === 401) {
        window.location.href = "/unauthorized";
      } else {
        window.location.href = "/login";
      }
    }
  };

  return (
    <>
      <IconButton
        onClick={() => {
          const confirmDel = window.confirm(
            `sei sicuro di voler cancellare questa tupla con id ${id} dal databse?`
          );
          if (confirmDel) del();
        }}
      >
        <DeleteIcon color="primary" />
      </IconButton>
    </>
  );
};

export default Delete;