import { useEffect } from 'react';

import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { Box, Container, CssBaseline } from "@mui/material";

import BackButton from "../../components/BackButton";
import Copyright from "../../components/layout/Copyright";
import LogoPublic from "../../components/layout/LogoPublic";

const Unauthorized = () => {

	useEffect(() => {
		document.body.style.backgroundColor = "#005ca9";
	  }, []);

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					color: "white"
				}}
			>
				<LogoPublic sx={{ maxWidth: "100px" }} />
				<DoDisturbIcon sx={{ fontSize: "1000%", mt: 10 }} />
				<h1>ACCESSO NEGATO</h1>
				<p>Non hai i permessi per poter accedere alla pagina!</p>
				<BackButton />
				<Copyright sx={{ color: "!important white" }} />
			</Box>
		</Container>
	);
};

export default Unauthorized;

	
	