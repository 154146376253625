import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { isMobile } from "react-device-detect";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Divider } from "@mui/material";

const UsersMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        fullWidth={isMobile || (window.innerWidth <= 785 && true)}
        sx={{
          color: !isMobile && "white",
          borderBottom: isMobile && "1px solid #ddd",
          mb: isMobile && 1,
        }}
        endIcon={
          <KeyboardArrowDownIcon sx={{ transform: open && "rotate(180deg)" }} />
        }
      >
        UTENTI
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          style: { color: "white", background: "#005ca9", border: "1px solid white", borderRadius: 4 },
        }}
      >
        <MenuItem
          onClick={() =>
            (window.location.href = "/area_controller/utenti/nuovo")
          }
        >
          <b>NUOVO UTENTE</b>
        </MenuItem>
        <Divider sx={{ background: "white" }}/>
        <MenuItem
          onClick={() =>
            (window.location.href = "/area_controller/utenti/riparatori")
          }
        >
          <b>RIPARATORI</b>
        </MenuItem>
        <Divider sx={{ background: "white" }}/>
        <MenuItem
          onClick={() =>
            (window.location.href = "/area_controller/utenti/periti")
          }
        >
          <b>PERITI</b>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UsersMenu;
