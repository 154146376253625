
import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Box, Button, IconButton, Paper, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";

import Update from "./Update";
import Delete from "./Delete";
import Create from "./Create";
import NavBar from "../../../components/navbar/NavBar";
import useGetUnlimitedListOf from "../../../hooks/useGetUnlimitedListOf";

const Users_Table = ({roles,showInactive}) => {
  const table = "Users";
  const axiosPrivate = useAxiosPrivate();
  const temp = useGetUnlimitedListOf(table, "", showInactive === "true" ? "list_without_active" : "list");

  const handleReactiveRecord = async (data) => {
    try {
      const target = { 
				code: data.code,
			};
      const response = await axiosPrivate.post(
        `/${table}/reactive_record`,
        target
      );
      if (response) {
        window.alert("Riattivazione avvenuta con successo!");
        window.location.reload();
      }
    } catch (err) {
      if (err.response?.status === 401) {
        window.location.href = "/unauthorized";
      } else {
        window.location.href = "/login";
      }
    }
  };

  const handleSuspendRecord = async (data) => {
    try {
      const target = { 
				code: data.code,
			};
      const response = await axiosPrivate.post(
        `/${table}/suspend_record`,
        target
      );
      if (response) {
        window.alert("Disattivazione avvenuta con successo!");
        window.location.reload();
      }
    } catch (err) {
      if (err.response?.status === 401) {
        window.location.href = "/unauthorized";
      } else {
        window.location.href = "/login";
      }
    }
  };
  const data = temp.listElements;

  const columns = [
    { field: "code", headerName: "code", flex: 1 },
    { field: "role_code", headerName: "role_code", flex: 1 },
    { field: "email", headerName: "email", flex: 1 },
    {
      field: "actions",
      headerName: "Azioni",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => {
              setSelectedRowId(params.row.id);
              handleOpen(true, "mod");
            }}
          >
            <EditIcon color="primary" />
          </IconButton>
          {modOpen && selectedRowId === params.row.id && (
            <Update handleOpen={handleOpen} open={modOpen} id={selectedRowId} />
          )}
          <Delete id={params.row.id} />
          {showInactive === "true" ?
			<IconButton
           	onClick={() => handleReactiveRecord({code: params.row.code,})}
           >
               <CheckIcon color="primary" />
           </IconButton>
        : showInactive === "false" &&
			<IconButton
           	onClick={() => handleSuspendRecord({code: params.row.code,})}
           >
               <DoDisturbIcon color="primary" />
           </IconButton>
        }
        </>
      ),
    },
  ];

  const [modOpen, setModOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleOpen = (value, type) => {
    if (type === "mod") setModOpen(value);
    else setCreateOpen(value);
  };

  return (
    <>
      <NavBar roles={roles}/>
      <Typography variant="h4" sx={{ m: 3 }}>
        Tabella {table}
      </Typography>
			<ToggleButtonGroup
				color="primary"
				variant="contained"
				value={showInactive}
				exclusive
				onChange={(e) => {
					if(e.target.value=="true")
						window.location.href += "/inactive"
					else
						window.location.href = window.location.href.substring(0, window.location.href.lastIndexOf('/'))
				}}
				sx={{ mb: 2 }}
			>
				<ToggleButton value="false">Attivi</ToggleButton>
				<ToggleButton value="true">Inattivi</ToggleButton>
			</ToggleButtonGroup>
      <Box
        display={"flex"}
        justifyContent="center"
        alignItems="center"
        flexDirection={"column"}
      >
        <Paper
          sx={{
            height: 400,
            width: "90%",
            overflow: "auto",
          }}
        >
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            getRowId={(row) => row.code}
            slots={{ toolbar: GridToolbar }}
          />
        </Paper>
        <Button sx={{ mt: 3 }} onClick={() => handleOpen(true, "create")}>
          <Typography> Aggiungi un elemento alla tabella </Typography>
          <AddIcon color="primary" />
        </Button>
        {createOpen && (
          <Create columns={columns} handleOpen={handleOpen} open={createOpen} />
        )}
      </Box>
    </>
  );
};

export default Users_Table;