import { useEffect, useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useGetElemDataById from "../../../hooks/useGetElemDataById";
import useGetUnlimitedListOf from "../../../hooks/useGetUnlimitedListOf";

const Update = ({ handleOpen, open, id }) => {
  const table = "Practice_Users"
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();

  const prev = useGetElemDataById(table, id,  "");

  const [selecteduser_code, setSelecteduser_code] = useState();
  const [selectedpractice_code, setSelectedpractice_code] = useState();
  const usersTemp = useGetUnlimitedListOf("Users", "", "list");
  const usersData = usersTemp.listElements;

  const practicesTemp = useGetUnlimitedListOf("Practices", "", "list");
  const practicesData = practicesTemp.listElements;

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

useEffect(() => {
    if (prev) {
      if(prev.user_code) {
        setSelecteduser_code(prev.user_code);
        setValue("user_code", prev.user_code);
      }
      if(prev.practice_code) {
        setSelectedpractice_code(prev.practice_code);
        setValue("practice_code", prev.practice_code);
      }
    }
  }, [prev]);

  const update = async (data) => {
if (!data.user_code?.trim() || !data.practice_code?.trim()) {
    window.alert("user_code, practice_code non possono essere vuoti!");
    return;
  }


    const nullColumns = Object.keys(data)
    .filter(key => data[key] === null || data[key] === "")
    .join("|");

    const deletedData = data;
    delete deletedData.id;
    try {
      const updatedData = {
				user_code: prev.user_code,
				practice_code: prev.practice_code,

        new_values: deletedData,
        str_list_null: nullColumns,
      };

      console.log(updatedData);

      const response = await axiosPrivate.put(`/${table}/update`, updatedData);
      if (response) {
        window.alert("Modifica avvenuta con successo!");
        window.location.reload();
      }
    } catch (err) {
      if (err.response?.status === 401) {
        window.location.href = "/unauthorized";
      } else {
        window.location.href = "/login";
      }
    }
  };

  if (prev.length !== 0) {
    return (
      <>

        <Modal open={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column",
            }}
            alignItems={"center"}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{ mb: 2 }}
            >
              <Typography
                id="keep-mounted-modal-title"
                variant="h6"
                component="h2"
              >
                Aggiorna l'elemento con id {id} alla tabella {table}
              </Typography>
              <Button
                color="error"
                onClick={() => handleOpen(false, "mod")}
                sx={{
                  maxWidth: "30px",
                  maxHeight: "30px",
                  minWidth: "30px",
                  minHeight: "30px",
                }}
              >
                <CloseIcon />
              </Button>
            </Box>
            {/* Autocomplete per il campo user_code */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={usersData}
              getOptionLabel={(option) => option.code || ""}
              value={
                selecteduser_code
                  ? usersData.find((user) => user.code === selecteduser_code)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelecteduser_code(newValue.code);
                  setValue("user_code", newValue.code);
                } else {
                  setSelecteduser_code(null);
                  setValue("user_code", "");
              }}}
              isOptionEqualToValue={(option, value) =>
                option.code === value.code
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select user_code"
                  placeholder="user_code"
                  error={!!errors.user_code}
                  helperText={
                    errors.user_code ? "Il campo user_code è obbligatorio" : ""
                  }
                  {...register("user_id")}
                />
              )}
            />
            {/* Autocomplete per il campo practice_code */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={practicesData}
              getOptionLabel={(option) => option.code || ""}
              value={
                selectedpractice_code
                  ? practicesData.find((practice) => practice.code === selectedpractice_code)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedpractice_code(newValue.code);
                  setValue("practice_code", newValue.code);
                } else {
                  setSelectedpractice_code(null);
                  setValue("practice_code", "");
              }}}
              isOptionEqualToValue={(option, value) =>
                option.code === value.code
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select practice_code"
                  placeholder="practice_code"
                  error={!!errors.practice_code}
                  helperText={
                    errors.practice_code ? "Il campo practice_code è obbligatorio" : ""
                  }
                  {...register("user_id")}
                />
              )}
            />
						{Object.entries(prev)
              .filter(([key]) => true && key !== "user_code" && key !== "practice_code" && key !== "active_state")
              .map(([key, value], index) => (
                <TextField
                  key={index}
                  placeholder={key}
                  defaultValue={value}
                  sx={{ width: "50%", m: 1 }}
                  {...register(key)}
                />
              ))}
            <Button onClick={handleSubmit(update)}>SALVA MODIFICHE</Button>
          </Box>
        </Modal>
      </>
    );
  }
};

export default Update;