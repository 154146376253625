import { Backdrop, Box, CircularProgress, Container, Paper, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
//   import UsersDetailData from "./users_detail_elems/UsersDetailData";
import PropTypes from "prop-types";
import PracticesNewType from "./practices_new_modules/PracticesNewType";
import PracticesNewData from "./practices_new_modules/PracticesNewData";
import PracticesNewFiles from "./practices_new_modules/PracticesNewFiles";
import { isMobile } from "react-device-detect";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
//   import UsersDetailPromotions from "./users_detail_elems/UsersDetailPromotions";
//   import UsersDetailFiles from "./users_detail_elems/UsersDetailFiles";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PracticesNew = ({ user }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [businessName, setBusinessName] = useState();
  const [value, setValue] = useState(0);
  const [accidentType, setAccidentType] = useState("");
  const [licensePlate, setLicensePlate] = useState("");
  const [insuredPersonName, setInsuredPersonName] = useState("");
  const [accidentLocation, setAccidentLocation] = useState();
  const [accidentDateTime, setAccidentDateTime] = useState();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSetValue = (value, type) => {
    switch (type) {
      case "type":
        setAccidentType(value);
        break;

      case "plate":
        setLicensePlate(value);
        break;

      case "name":
        setInsuredPersonName(value);
        break;

      case "location":
        setAccidentLocation(value);
        console.log(value);
        break;

      case "date_time":
        setAccidentDateTime(value);
        console.log(value);
        break;

      case "files":
        setFiles(value);
        break;

      default:
        break;
    }
  };

  const handleCreatePractice = async (files_data) => {
    setLoading(true);

    const formData = new FormData();

    const practiceData = {
      practice_type_id: accidentType.id,
      title:
        licensePlate.replace(/\s+/g, "").toUpperCase() +
        " - " +
        insuredPersonName,
      insured_plate_number: licensePlate,
      insured_name: insuredPersonName,
    };

    formData.append("practice", JSON.stringify(practiceData));

    if (
      (typeof accidentLocation !== "undefined" && accidentLocation !== "") ||
      (typeof accidentDateTime !== "undefined" && accidentDateTime !== "")
    ) {
      const practiceAdditionalInfos = {
        accident_location: accidentLocation,
        accident_date: accidentDateTime,
      };

      formData.append(
        "practice_additional_infos",
        JSON.stringify(practiceAdditionalInfos)
      );
    }

    console.log(files_data);
    if (files_data.length !== 0) {
      for (let i = 0; i < files_data.length; i++) {
        formData.append("practice_attachments", files_data[i].file);
      }
    }

    try {
      const upload = await axiosPrivate.post(
        "/Practices/create_complete",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (upload) {
        window.alert("Pratica creata con successo!");
        if (user === "Repairer")
          navigate(`/le_mie_pratiche/${upload.data.result.res_code}/`);
        else navigate(`/pratiche/${upload.data.result.res_code}/`);
      }
    } catch (err) {
      setLoading(false);
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };

  return (
    <>
      <Container component={Paper} sx={{ pt: 1, px: 0 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            variant="fullWidth"
            orientation={isMobile && "vertical"}
            TabIndicatorProps={{
              sx: {
                width: "96%",
                zIndex: 0,
                left: "2%",
                borderRadius: 2,
                boxShadow: 2,
              },
            }}
          >
            <Tab
              sx={{ py: isMobile && 4 }}
              disabled={value !== 0}
              label={
                <Typography
                  variant="h6"
                  fontSize={15}
                  sx={{ zIndex: 1, color: value === 0 && isMobile && "white" }}
                >
                  Tipologia sinistro
                  <br />
                  <i
                    style={{
                      color: value === 0 && isMobile ? "white" : "black",
                    }}
                  >
                    {accidentType.value}
                  </i>
                </Typography>
              }
              {...a11yProps(0)}
            />
            <Tab
              sx={{ py: isMobile && 4 }}
              disabled={value !== 1}
              label={
                <Typography
                  variant="h6"
                  fontSize={15}
                  sx={{ zIndex: 1, color: value === 1 && isMobile && "white" }}
                >
                  Dati
                  <br />
                  <i
                    style={{
                      color: value === 1 && isMobile ? "white" : "black",
                    }}
                  >
                    {licensePlate + " " + insuredPersonName}
                  </i>
                </Typography>
              }
              {...a11yProps(1)}
            />
            <Tab
              sx={{ py: isMobile && 4 }}
              disabled={value !== 2}
              label={
                <Typography
                  variant="h6"
                  fontSize={15}
                  sx={{ zIndex: 1, color: value === 2 && isMobile && "white" }}
                >
                  Documenti e foto
                </Typography>
              }
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <Box sx={{ mt: -2 }}>
          <CustomTabPanel value={value} index={0}>
            <PracticesNewType
              handleSetValue={handleSetValue}
              handleTabChange={handleTabChange}
              accidentType={accidentType}
            />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <PracticesNewData
              handleSetValue={handleSetValue}
              handleTabChange={handleTabChange}
              licensePlate={licensePlate}
              insuredPersonName={insuredPersonName}
            />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={2}>
            <PracticesNewFiles
              handleSetValue={handleSetValue}
              handleTabChange={handleTabChange}
              handleCreatePractice={handleCreatePractice}
              loading={loading}
              filesList={files}
            />
          </CustomTabPanel>
        </Box>
      </Container>
      {loading && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: 10000,
          }}
          open={loading}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      )}
    </>
  );
};

export default PracticesNew;
