import { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import useAxiosPrivate from "../useAxiosPrivate";

const useGetUserCompleteInfo = (userCode) => {
	const axiosPrivate = useAxiosPrivate();
	const navigate = useNavigate();
	const location = useLocation();

	const [data, setData] = useState([]);

	if (userCode !== "") userCode = "?code=" + userCode;

	useEffect(() => {
		let isMounted = true;
		const controller = new AbortController();

		const infos = async () => {
			try {
				const response = await axiosPrivate.get(
					"/Users/get_complete_information" + userCode
				);
				if (isMounted) {
					setData(response.data?.result);
				}
			} catch (err) {
				if (err.response?.status === 401) {
					window.location.href = "/unauthorized";
				} else {
			// navigate("*", { state: { from: location }, replace: true });
				}
			}
		};

		infos();

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, [axiosPrivate, location, navigate, userCode]);

	return data;
};

export default useGetUserCompleteInfo;
	