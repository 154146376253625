import React from "react";
import NavBar from "../../../../components/navbar/NavBar";
import { Typography } from "@mui/material";
import PracticesNew from "../../../../components/practices/PracticesNew";
import { isMobile } from "react-device-detect";

const RepairerNewPractice = () => {
  return (
    <>
      <NavBar type="6093" />
      <Typography variant="h5" color="primary" sx={{ mt: isMobile ? 12 : 3, mb: 1}}>
        Nuova pratica
      </Typography>
      <PracticesNew user="Repairer" />
    </>
  );
};

export default RepairerNewPractice;
