import React, { useEffect, useRef, useState } from "react";

import { useSearchParams } from "react-router-dom";

import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";

import useGetListOf from "../../hooks/useGetListOf";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const PracticesMessagesDrawer = ({ practiceCode, toggleDrawer }) => {
  const axiosPrivate = useAxiosPrivate();
  const [searchParams, setSearchParams] = useSearchParams();
  const messages = useGetListOf(
    "Practice_Messages",
    `where_key=practice_code&where_value=${practiceCode}&complete=true&order_key=message.created_at&order_desc=false`,
    "list"
  ).listElements;
  const [message, setMessage] = useState("");
  const scrollBoxRef = useRef(null);
  const colorMap = useRef({});
  const [loading, setLoading] = useState(false)

  const generateColor = (name) => {
    const highContrastColors = [
      "#FF5733", // Rosso vivo
      "#33FF57", // Verde vivo
      "#FF33A1", // Rosa
      "#8E44AD", // Viola
      "#16A085", // Verde acqua
      "#2C3E50", // Blu scuro
      "#F39C12", // Arancione
      "#C0392B", // Rosso scuro
      "#2980B9", // Azzurro
      "#27AE60", // Verde scuro
    ];

    if (!colorMap.current[name]) {
      // Se il nome non ha già un colore, assegnagliene uno dalla lista
      const index =
        Object.keys(colorMap.current).length % highContrastColors.length;
      colorMap.current[name] = highContrastColors[index];
    }
    return colorMap.current[name];
  };

  useEffect(() => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollTop = scrollBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const handleConvertTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    if (isNaN(date.getTime())) {
      console.error("Timestamp non valido:", timestamp);
      return "Data non disponibile";
    }

    let formattedDate = new Intl.DateTimeFormat("it-IT", {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(date);

    formattedDate = formattedDate
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    formattedDate = formattedDate.replace("Alle", "alle");

    return formattedDate;
  };

  const handleSendMessage = async () => {
    setLoading(true)
    try {
      const response = await axiosPrivate.post(
        "/Practice_Messages/send_message",
        {
          practice_code: practiceCode,
          message: message,
        }
      );
      if (response) {
        setSearchParams("");
        setMessage("");
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      console.error("Errore durante la richiesta:", err);
      if (!err?.response) {
        window.alert("Errore. Riprova");
      } else if (err.response?.status === 401) {
        window.alert("Non sei autorizzato");
      } else {
        window.alert("Errore. Riprova");
      }
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        px: "3%",
        bgcolor: "#f5f5f5", // Sfondo chiaro per contrasto
        borderRadius: 3,
        boxShadow: 2,
        height: "72%",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <List
        style={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
        ref={scrollBoxRef}
      >
        {messages.length !== 0 ? (
          messages.map((message) => (
            <ListItem
              key={message["message.code"]}
              disablePadding
              sx={{
                maxWidth: "75%",
                display: "block",
                border: "1px solid #ddd",
                p: 2,
                my: 1,
                ml: message["is_my_message"] ? "auto" : 0,
                borderRadius: 5,
                bgcolor: message["is_my_message"] ? "#e3f2fd" : "white", // Azzurro per i messaggi dell'utente
                wordBreak: "break-word",
                boxShadow: 1, // Leggera ombra per un effetto "card"
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: message["is_my_message"]
                    ? "#005ca9"
                    : generateColor(message["user_anagraphics.business_name"]),
                  fontWeight: "700",
                }}
              >
                {message["user_anagraphics.business_name"]}
              </Typography>
              <ListItemText
                primary={message["messages.message"]}
                sx={{
                  mt: 1,
                  fontSize: 14,
                  color: "#333",
                }}
              />
              <Typography
                sx={{
                  fontSize: 10,
                  textAlign: "right",
                  color: "#999",
                  mt: 1,
                }}
              >
                {handleConvertTimestamp(message["messages.created_at"])}
              </Typography>
            </ListItem>
          ))
        ) : (
          <Typography
            variant="h5"
            color="primary"
            sx={{
              m: "auto",
              textAlign: "center",
              mt: 2,
            }}
          >
            Nessun messaggio
          </Typography>
        )}
      </List>

      {/* Input Box */}
      <Box
        sx={{
          display: "flex",
          bottom: 60,
          width: "100%",
          mt: 2,
          mx: "auto",
          background: "white",
          borderRadius: 2,
          p: 1,
          boxShadow: 3, // Aggiunta ombra per staccarlo dallo sfondo
        }}
      >
        <TextField
          fullWidth
          multiline
          maxRows={4}
          value={message}
          variant="outlined" // Modernizzato con bordi evidenti
          label="Scrivi un messaggio"
          sx={{
            bgcolor: "#f9f9f9", // Sfondo chiaro del campo di testo
            borderRadius: 1,
          }}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => setMessage(e.target.value)}
          disabled={loading}
        />
        <Button
          disabled={message === "" || loading}
          variant="contained"
          sx={{
            ml: 2,
            bgcolor: "#1976d2", // Blu per il pulsante
            color: "white",
            "&:hover": {
              bgcolor: "#1565c0", // Blu scuro al passaggio
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleSendMessage();
          }}
        >
          {loading ? <CircularProgress /> : "Invia"}
        </Button>
      </Box>
    </Box>
  );
};

export default PracticesMessagesDrawer;
