	import { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import useAxiosPrivate from "./useAxiosPrivate";

const useGetUnlimitedListOf = (listType, backendParams, defaultList) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [listElements, setListElements] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  useEffect(() => {
	let isMounted = true;
	const controller = new AbortController();

	const list = async () => {
	  if (listType !== "") {
		try {
		  const response = await axiosPrivate.get(
			`/${listType}/${defaultList}?${backendParams}`
		  );
		  if (isMounted) {
			setListElements(response.data?.result.list_element); // && => se isMounted == true
			setTotalElements(response.data?.result.total_element);
		  }
		} catch (err) {
		  if (err.response?.status === 401) {
			window.location.href = "/unauthorized";
		  } else {
			navigate("/login", { state: { from: location }, replace: true });
		  }
		}
	  }
	};

	list();

	return () => {
	  isMounted = false;
	  controller.abort();
	};
  }, [axiosPrivate, location, navigate]);

  return {
	listElements: listElements,
	totalElements: totalElements,
  };
};

export default useGetUnlimitedListOf;

	