import React from "react";
import NavBar from "../../../../components/navbar/NavBar";
import ElementsList from "../../../../components/ElementsList";
import { Typography } from "@mui/material";
import { isMobile } from "react-device-detect";

const IntermediaryPractices = () => {
  return (
    <>
      <NavBar type="2039" />
      <Typography variant="h5" color="primary" sx={{ mt: isMobile ? 12 : 3, mb: 1}}>
        Le mie pratiche
      </Typography>
      <ElementsList type="Practices" user="Intermediary" variantSearchKeys="order_key=created_at&order_desc=true" />
    </>
  );
};

export default IntermediaryPractices;
