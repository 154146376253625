import React from 'react'
import NavBar from '../../../../components/navbar/NavBar'
import { Typography } from '@mui/material'
import { isMobile } from 'react-device-detect'
import UsersNew from "../../../../components/users/UsersNew"

const ControllerUsersNew = () => {
  return (
    <>
      <NavBar type="8711" />
      <Typography
        variant="h5"
        color="primary"
        sx={{ mt: isMobile ? 12 : 3, mb: 1 }}
      >
        Nuovo utente
      </Typography>
      <UsersNew user="Controller" />
    </>
  )
}

export default ControllerUsersNew