import { useEffect } from "react";

import { Box, Container, CssBaseline } from "@mui/material";
import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";

import BackButton from "../../components/BackButton";
import Copyright from "../../components/layout/Copyright";
import LogoPublic from "../../components/layout/LogoPublic";

const Missing = () => {

	useEffect(() => {
		document.body.style.backgroundColor = "#005ca9";
	  }, []);

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					color: "white"
				}}
			>
				<LogoPublic sx={{ maxWidth: "100px" }} />
				<NotListedLocationIcon sx={{ fontSize: "1000%", mt: 10 }} />
				<h1>Oops!</h1>
				<p>La pagina non esiste!</p>
				<BackButton />
				<Copyright sx={{ color: "!important white" }} />
			</Box>
		</Container>
	);
};

export default Missing;	
	