import { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import useAxiosPrivate from "../useAxiosPrivate";

const useGetPracticeCompleteData = (practiceCode) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const infos = async () => {
      try {
        const response = await axiosPrivate.get(
          "/Practices/get_complete_by_code?code=" + practiceCode
        );
        if (isMounted) {
          setData(response.data?.result);
        }
      } catch (err) {
        if (err.response?.status === 401) {
			window.location.href = "/unauthorized";
        } else if (err.response?.status === 404) {
          window.location.href = "/*";
        }
      }
    };

    infos();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [axiosPrivate, location, navigate, practiceCode]);

  return data;
};

export default useGetPracticeCompleteData;
