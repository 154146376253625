import React, { useState } from "react";

import dayjs from "dayjs";
import { isMobile } from "react-device-detect";

import { Box } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import useGetListOf from "../../../hooks/useGetListOf";
import LoadingSpinner from "../../layout/LoadingSpinner";

const PracticesNewData = ({
  handleSetValue,
  handleTabChange,
  licensePlate,
  insuredPersonName,
  accidentLocation,
  accidentDateTime,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [plate, setPalte] = useState(licensePlate);
  const [name, setName] = useState(insuredPersonName);
  const [location, setLocation] = useState(accidentLocation);
  const [dateTime, setDateTime] = useState(accidentDateTime);

  const handleSubmit = () => {
    setLoading(true);
    handleSetValue(plate, "plate");
    handleSetValue(name, "name");
    if (location !== null && location !== "")
      handleSetValue(location, "location");
    if (dateTime !== null && dateTime !== "")
      handleSetValue(dateTime, "date_time");
    setTimeout(() => {
      handleTabChange("", 2);
      setLoading(false);
    }, "1000");
  };

  return (
    <>
      <Typography variant="h6" color="primary" sx={{ my: 2 }}>
        Inserisci la targa del veicolo e il nome dell'intestatario del contratto
        di assicurazione
      </Typography>
      <Box sx={{ width: isMobile ? "100%" : "50%", mx: "auto", mt: 2 }}>
        <TextField
          required
          sx={{ mb: 2 }}
          label="Targa del veicolo"
          defaultValue={licensePlate}
          disabled={loading}
          fullWidth
          onChange={(e) => {
            setPalte(e.target.value);
          }}
        />
        <TextField
          required
          label="Nome e cognome dell'assicurato (o soggetto giuridico)"
          defaultValue={insuredPersonName}
          disabled={loading}
          fullWidth
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <Typography variant="caption">* campi obbligatori</Typography>
        <TextField
          sx={{ mt: 3 }}
          label="Luogo del sinistro (opzionale)"
          defaultValue={insuredPersonName}
          disabled={loading}
          fullWidth
          onChange={(e) => {
            setLocation(e.target.value);
          }}
        />
        <Box sx={{ my: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CalendarMonthIcon /> Data e ora sinistro (opzionale)
                </Box>
              }
              ampm={false}
              maxDateTime={dayjs()}
              format="DD/MM/YYYY HH:mm"
              onChange={(date) => {
                setDateTime(dayjs(date).format("YYYY-MM-DD HH:mm:ss.SSS"));
              }}
              sx={{ width: "100%" }}
            />
          </LocalizationProvider>
        </Box>
        <Button
          fullWidth
          disabled={plate.length === 0 || name.length === 0 || loading}
          onClick={() => handleSubmit()}
        >
          <DoneIcon /> CONFERMA
        </Button>
        <Button
          size="small"
          fullWidth
          color="error"
          onClick={() => handleTabChange("", 0)}
        >
          <ArrowBackIcon /> INDIETRO
        </Button>
      </Box>
      {loading == true && (
        <Box sx={{ mt: 1, mb: -1.2 }}>
          <LoadingSpinner />
        </Box>
      )}
    </>
  );
};

export default PracticesNewData;
