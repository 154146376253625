import styled from "@emotion/styled";
import { Button, Divider, Paper, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import useGetPracticesStats from "../../hooks/practices/useGetPracticesStats";
import useGetListOf from "../../hooks/useGetListOf";
import PracticesCounterDisplayer from "./PracticesCounterDisplayer";
import { isMobile } from "react-device-detect";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { itIT } from "@mui/x-date-pickers/locales";

const PracticesStats = ({ user, userCode }) => {
  // const [searchParams, setSearchParams] = useSearchParams();
  const [startDateTime, setStartDateTime] = useState("Invalid Date");
  const [endDateTime, setEndDateTime] = useState("Invalid Date");
  const [timeQuery, setTimeQuery] = useState("");
  const practiceStates = useGetListOf(
    "Practice_States",
    `order_key=id`,
    "list"
  ).listElements;
  const repairersCount = useGetListOf(
    "Users",
    `where_key=role_name&where_value=Repairer&complete=true`,
    "list"
  ).totalElements;
  const intermediariesCount = useGetListOf(
    "Users",
    `where_key=role_name&where_value=Intermediary&complete=true`,
    "list"
  ).totalElements;
  const allPracticesCount = useGetPracticesStats("", userCode, timeQuery);

  useEffect(() => {
    let timeQuery = "?";

    if (startDateTime !== "Invalid Date")
      timeQuery += `start=${startDateTime}&`;
    if (endDateTime !== "Invalid Date") timeQuery += `end=${endDateTime}&`;

    setTimeQuery(timeQuery);
  }, [startDateTime, endDateTime]);

  return (
    <Container
      component={Paper}
      maxWidth="xl"
      sx={{ p: 3, mb: 2, mt: isMobile && 12, display: !isMobile && "flex" }}
    >
      <Container
        component={Paper}
        maxWidth="md"
        sx={{ p: isMobile ? 1 : 3, mb: 2 }}
      >
        <Box sx={{ mt: -1, mb: 2, display: "flex" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CalendarMonthIcon /> Da data
                </Box>
              }
              ampm={false}
              maxDateTime={dayjs()}
              format="DD/MM/YYYY HH:mm"
              onChange={(date) => {
                setStartDateTime(dayjs(date).format("YYYY-MM-DD HH:mm:ss.SSS"));
              }}
              sx={{ width: "100%", mr: 1 }}
              slotProps={{
                actionBar: {
                  actions: ["today", "clear", "accept"],
                },
              }}
              localeText={{
                clearButtonLabel: "Cancella data",
                todayButtonLabel: "Seleziona oggi",
              }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CalendarMonthIcon /> A data
                </Box>
              }
              ampm={false}
              maxDateTime={dayjs()}
              format="DD/MM/YYYY HH:mm"
              onChange={(date) => {
                setEndDateTime(dayjs(date).format("YYYY-MM-DD HH:mm:ss.SSS"));
              }}
              sx={{ width: "100%", ml: 1 }}
              slotProps={{
                actionBar: {
                  actions: ["today", "clear", "accept"],
                },
              }}
              localeText={{
                clearButtonLabel: "Cancella data",
                todayButtonLabel: "Seleziona oggi",
              }}
            />
          </LocalizationProvider>
        </Box>
        <Box
          disabled={userCode}
          component={Button}
          onClick={() => {
            if (user === "Controller")
              window.location.href = "/area_controller/pratiche";
          }}
          sx={{
            display: "block",
            transition: "transform 0.2s ease-in-out",
            "&:hover": {
              transform: "scale(1.05)",
              border: "5px solid white",
              boxShadow: 10,
              cursor: "pointer",
            },
            "&:active": {
              transform: "translateY(-20px)",
              transition: "transform 0.2s",
            },
            "&:disabled": { color: "white" },
            color: "white",
            background: "#005ca9",
            width: "100%",
            height: 100,
            mb: 1,
            borderRadius: 2,
            boxShadow: 2,
            p: 1,
          }}
        >
          <Typography variant="h5">Totale pratiche</Typography>
          <Typography variant="h4">{allPracticesCount}</Typography>
        </Box>
        <Stack
          spacing={{ sm: "1%", xs: "2%" }}
          direction="row"
          useFlexGap
          sx={{ flexWrap: "wrap", justifyContent: "center" }}
        >
          {practiceStates.map((state) => (
            <PracticesCounterDisplayer
              user={user}
              status={state.value}
              userCode={userCode}
              timeQuery={timeQuery}
            />
          ))}
        </Stack>
      </Container>
      {!userCode && (
        <Container
          component={Paper}
          maxWidth="sm"
          sx={{ p: isMobile ? 1 : 3, mb: 2 }}
        >
          <Box
            component={Button}
            onClick={() => {
              if (user === "Controller")
                window.location.href = "/area_controller/utenti/riparatori";
              else if (user === "Cna")
                window.location.href = "/utenti/riparatori";
            }}
            sx={{
              display: "block",
              transition: "transform 0.2s ease-in-out",
              "&:hover": {
                transform: "scale(1.05)",
                border: "5px solid white",
                boxShadow: 10,
                cursor: "pointer",
              },
              "&:active": {
                transform: "translateY(-20px)",
                transition: "transform 0.2s",
              },
              color: "white",
              background: "#005ca9",
              width: "100%",
              height: 150,
              mb: 2,
              borderRadius: 2,
              boxShadow: 2,
              p: 1,
            }}
          >
            <Typography variant="h5">{repairersCount} Riparatori</Typography>
            <Typography variant="h4">
              <ArrowForwardIcon style={{ fontSize: 40 }} />
            </Typography>
          </Box>
          <Box
            component={Button}
            onClick={() => {
              if (user === "Controller")
                window.location.href = "/area_controller/utenti/periti";
              else if (user === "Cna")
                window.location.href = "/utenti/periti";
            }}
            sx={{
              display: "block",
              transition: "transform 0.2s ease-in-out",
              "&:hover": {
                transform: "scale(1.05)",
                border: "5px solid white",
                boxShadow: 10,
                cursor: "pointer",
              },
              "&:active": {
                transform: "translateY(-20px)",
                transition: "transform 0.2s",
              },
              color: "white",
              background: "#005ca9",
              width: "100%",
              height: 150,
              mb: 2,
              borderRadius: 2,
              boxShadow: 2,
              p: 1,
            }}
          >
            <Typography variant="h5">{intermediariesCount} Periti</Typography>
            <Typography variant="h4">
              <ArrowForwardIcon style={{ fontSize: 40 }} />
            </Typography>
          </Box>
          {user === "Controller" && (
            <Box
              component={Button}
              onClick={() => {
                if (user === "Controller")
                  window.location.href = "/area_controller/utenti/nuovo";
              }}
              sx={{
                display: "block",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.05)",
                  border: "5px solid white",
                  boxShadow: 10,
                  cursor: "pointer",
                },
                "&:active": {
                  transform: "translateY(-20px)",
                  transition: "transform 0.2s",
                },
                color: "white",
                background: "#005ca9",
                width: "100%",
                height: 150,
                borderRadius: 2,
                boxShadow: 2,
                p: 1,
              }}
            >
              <Typography variant="h5">Crea utente</Typography>
              <Typography variant="h4">
                <ArrowForwardIcon style={{ fontSize: 40 }} />
              </Typography>
            </Box>
          )}
        </Container>
      )}
    </Container>
  );
};

export default PracticesStats;
