  import { findValueByRoleId } from "./func";
import Swal from "sweetalert2";

//401: unauthorized
//403: forbidden
//404: not found
//405: bad request - (method not allowed)
//422: unprocessable entity

const handleRolesErrors = (
	removeErrors,
	addErrors,
	permissionsArray,
	roles,
	addRole,
	navigate
  ) => {
	let errorMessage = "";
  
	if (removeErrors.length > 0 || addErrors.length > 0) {
	  console.warn(`Rilevati ${removeErrors.length + addErrors.length} errori`);
	  console.warn("remove errors: ", removeErrors);
	  console.warn("add errors: ", addErrors);
  
	  if (removeErrors.length > 0) {
		errorMessage += `<p>Non � stato possibile rimuovere alcuni permessi:</p><ul>`;
		removeErrors.forEach((err, index) => {
		  const roleError = findValueByRoleId(
			err.removedRole.permission_code,
			roles
		  );
		  errorMessage += `<li><strong>${index + 1}.</strong> Permesso: ${roleError} - Errore: ${err.error}</li>`;
		});
		errorMessage += `</ul>`;
	  }
  
	  if (addErrors.length > 0) {
		errorMessage += `<p>Non � stato possibile aggiungere alcuni permessi:</p><ul>`;
		addErrors.forEach((err, index) => {
		  let aux = "";
		  Object.keys(err.filteredPayload).forEach((key) => {
			aux += findValueByRoleId(err.filteredPayload[key], roles) + " -> ";
		  });
		  aux = aux.slice(0, -4); // Rimuove l'ultimo " -> "
		  errorMessage += `<li><strong>${index + 1}.</strong> Permesso: ${aux} - Errore: ${err.error}</li>`;
		});
		errorMessage += `</ul>`;
	  }
	} else {
	  if (permissionsArray.length > 0) {
		errorMessage += `<p>Tutte le rimozioni di permessi sono state effettuate con successo!</p>`;
	  }
	  if (addRole) {
		errorMessage += `<p>Tutte le aggiunte di permessi sono state effettuate con successo!</p>`;
	  }
	}
  
	Swal.fire({
	  title: "Esito Operazione",
	  html: errorMessage,
	  icon: removeErrors.length > 0 || addErrors.length > 0 ? "error" : "success",
	  confirmButtonText: "Ok",
	  confirmButtonColor: "green",
	  allowOutsideClick: false,
	}).then(() => {
	  navigate(0);
	});
  };
  

const handleHooksErrors = (errors, debug) => {
	if (debug === undefined) debug = false;
	if (errors.error.response?.status === 401) {
	  window.location.href = "/unauthorized";
	  return;
	}
  
	let errorMessage = "";
	if (errors) {
	  errorMessage += `<p><strong>Error:</strong> ${errors.error.response?.status} - ${errors.error.response?.statusText}</p>`;
  
	  if (!debug) {
		errorMessage += `<p><strong>Request:</strong> ${errors.call.slice(0, errors.call.indexOf("?"))}</p>`;
	  } else {
		errorMessage += `<p><strong>Request:</strong> ${errors.call}</p>`;
  
		if (errors.backendParams) {
		  errorMessage += "<p><strong>Params:</strong><br>";
		  const splittedParams = errors.backendParams.split("&");
		  splittedParams.forEach((splittedParam) => {
			errorMessage += `&nbsp;&nbsp;&nbsp;&nbsp;${splittedParam}<br>`;
		  });
		  errorMessage += "</p>";
		}
	  }
  
	  Swal.fire({
		title: "Errore!",
		html: errorMessage,
		icon: "error",
		confirmButtonText: "Ok",
		confirmButtonColor: "red",
		allowOutsideClick: false,
	  }).then(() => {
		if (errors.forceReload) {
		  window.location.reload();
		}
	  });
	}
  };
  

const handleCreateErrors = (errors, debug) => {
  if (debug === undefined) debug = false;
  if (errors.error.response?.status === 401)
	window.location.href = "/unauthorized";

  let errorMessage = "";
  if (errors) {
	errorMessage += `Error: ${errors.error.response.status} - ${errors.error.response.statusText}<br><br>`;
	errorMessage += `Request: ${errors.call}<br><br>`;

	const startingStr = "ViolationError";
	const endingStr = "DETAIL";

	const startIndex =
	  errors.error.response.data.detail.indexOf(startingStr) +
	  startingStr.length;

	const endIndex = errors.error.response.data.detail.indexOf(endingStr);

	const slicedStr = errors.error.response.data.detail
	  .slice(startIndex + 4, endIndex)
	  .trim();

	if (slicedStr.length > 0) errorMessage += `Detail: ${slicedStr}<br>`;

	if (debug) {
	  errorMessage += "Body";
	  for (const key in errors.callData)
		if (errors.callData.hasOwnProperty(key))
		  errorMessage += `<br>${key}: ${errors.callData[key]}`;
	}

	Swal.fire({
	  title: "Errore!",
	  html: errorMessage,
	  icon: "error",
	  confirmButtonText: "Ok",
	  confirmButtonColor: "red",
	  allowOutsideClick: false,
	  didClose: () => {
		if (errors.forceReload) {
		  Swal.fire({
			title: "Ricaricamento in corso...",
			allowOutsideClick: false,
			didOpen: () => {
			  Swal.showLoading();
			  setTimeout(() => {
				window.location.reload();
			  }, 500);
			},
		  });
		}
	  },
	});
  }
};

const handleUpdateErrors = (errors, debug) => {
  if (debug === undefined) debug = false;
  if (errors.error.response?.status === 401)
	window.location.href = "/unauthorized";

  let errorMessage = "";
  if (errors) {
	errorMessage += `Error: ${errors.error.response.status} - ${errors.error.response.statusText}<br><br>`;
	errorMessage += `Request: ${errors.call}<br><br>`;

	if (debug) {
	  errorMessage += "NewValues:";
	  for (const key in errors.newValues)
		if (errors.newValues.hasOwnProperty(key))
		  errorMessage += `<br>${key}: ${errors.newValues[key]}`;

	  const aux = errors.nullList.split(",");

	  errorMessage += "<br><br>Null list:";
	  aux.forEach((elem) => {
		errorMessage += `<br>${elem}`;
	  });
	}

	Swal.fire({
	  title: "Errore!",
	  html: errorMessage,
	  icon: "error",
	  confirmButtonText: "Ok",
	  confirmButtonColor: "red",
	  allowOutsideClick: false,
	  didOpen: () => {
		const popup = Swal.getPopup();
		if (popup) {
		  popup.style.zIndex = "1500";
		}
	  },
	  didClose: () => {
		if (errors.forceReload) {
		  Swal.fire({
			title: "Ricaricamento in corso...",
			allowOutsideClick: false,
			didOpen: () => {
			  Swal.showLoading();
			  setTimeout(() => {
				window.location.reload();
			  }, 500);
			},
		  });
		}
	  },
	});
  }
};

const handleActiveStateErrors = (errors, debug) => {
  if (debug === undefined) debug = false;
  if (errors.error.response?.status === 401)
	window.location.href = "/unauthorized";

  let errorMessage = "";
  if (errors) {
	errorMessage += `Error: ${errors.error.response.status} - ${errors.error.response.statusText}<br><br>`;
	errorMessage += `Request: ${errors.call}<br><br>`;
	if (debug) {
	  errorMessage += `Code: ${errors.userId}`;
	}
	Swal.fire({
	  title: "Errore!",
	  html: errorMessage,
	  icon: "error",
	  confirmButtonText: "Ok",
	  confirmButtonColor: "red",
	  allowOutsideClick: false,
	  didOpen: () => {
		const popup = Swal.getPopup();
		if (popup) {
		  popup.style.zIndex = "1500";
		}
	  },
	  didClose: () => {
		if (errors.forceReload) {
		  Swal.fire({
			title: "Ricaricamento in corso...",
			allowOutsideClick: false,
			didOpen: () => {
			  Swal.showLoading();
			  setTimeout(() => {
				window.location.reload();
			  }, 500);
			},
		  });
		}
	  },
	});
  }
};

export {
  handleCreateErrors,
  handleUpdateErrors,
  handleHooksErrors,
  handleRolesErrors,
  handleActiveStateErrors,
};

 

 