import { Box, Typography } from "@mui/material";

import ProfileData from "../../../components/users/ProfileData";
import ProfileDataEdit from "../../../components/users/ProfileDataEdit";
import ProfileDataChangePsw from "../../../components/users/ProfileDataChangePsw";
import useGetUserCompleteInfo from "../../../hooks/users/useGetUserCompleteInfo";
import NavBar from "../../../components/navbar/NavBar";
import { isMobile } from "react-device-detect";
// import { CreditsProvider } from "../../../context/CreditsProvider";

const Profile = ({ action }) => {
  const userData = useGetUserCompleteInfo("");

  if (userData.length !== 0 && userData !== null) {
    var userType = "";
    switch (userData.user["roles.value"]) {
      case "Admin":
        userType = "9495";
        break;

      case "Cna":
        userType = "5672";
        break;

      case "Controller":
        userType = "8711";
        break;

      case "Intermediary":
        userType = "2039";
        break;

      case "Repairer":
        userType = "6093";
        break;

      default:
        break;
    }

    return (
      <Box>
        <NavBar type={userType} />
        {action.value === "no-edit" ? (
          <>
            <Typography
              variant="h5"
              color="primary"
              sx={{ mt: isMobile ? 12 : 3, mb: 1 }}
            >
              Il mio profilo
            </Typography>
            <ProfileData userData={userData} />
          </>
        ) : action.value === "edit" ? (
          <>
            <Typography
              variant="h5"
              color="primary"
              sx={{ mt: isMobile ? 12 : 3, mb: 1 }}
            >
              Modifica profilo
            </Typography>
            <ProfileDataEdit userData={userData} />
          </>
        ) : action.value === "edit-psw" ? (
          <>
            <Typography
              variant="h5"
              color="primary"
              sx={{ mt: isMobile ? 12 : 3, mb: 1 }}
            >
              Cambio password
            </Typography>
            <ProfileDataChangePsw userData={userData} />
          </>
        ) : (
          console.log("error: no profile page type found")
        )}
      </Box>
    );
  }
};

export default Profile;
