import { useEffect, useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useGetElemDataById from "../../../hooks/useGetElemDataById";
import useGetUnlimitedListOf from "../../../hooks/useGetUnlimitedListOf";

const Update = ({ handleOpen, open, id }) => {
  const table = "Messages"
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();

  const prev = useGetElemDataById(table, id,  "code");

  const [selecteduser_code, setSelecteduser_code] = useState();
  const [selectedmessage_type_id, setSelectedmessage_type_id] = useState();
  const usersTemp = useGetUnlimitedListOf("Users", "", "list");
  const usersData = usersTemp.listElements;

  const message_typesTemp = useGetUnlimitedListOf("Message_Types", "", "list");
  const message_typesData = message_typesTemp.listElements;

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

useEffect(() => {
    if (prev) {
      if(prev.user_code) {
        setSelecteduser_code(prev.user_code);
        setValue("user_code", prev.user_code);
      }
      if(prev.message_type_id) {
        setSelectedmessage_type_id(prev.message_type_id);
        setValue("message_type_id", prev.message_type_id);
      }
    }
  }, [prev]);

  const update = async (data) => {
if (!data.message_type_id?.trim() || !data.message?.trim()) {
    window.alert("message_type_id, message non possono essere vuoti!");
    return;
  }


    const nullColumns = Object.keys(data)
    .filter(key => data[key] === null || data[key] === "")
    .join("|");

    const deletedData = data;
    delete deletedData.id;
    try {
      const updatedData = {
				code: prev.code,
        new_values: deletedData,
        str_list_null: nullColumns,
      };

      console.log(updatedData);

      const response = await axiosPrivate.put(`/${table}/update`, updatedData);
      if (response) {
        window.alert("Modifica avvenuta con successo!");
        window.location.reload();
      }
    } catch (err) {
      if (err.response?.status === 401) {
        window.location.href = "/unauthorized";
      } else {
        window.location.href = "/login";
      }
    }
  };

  if (prev.length !== 0) {
    return (
      <>

        <Modal open={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column",
            }}
            alignItems={"center"}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              sx={{ mb: 2 }}
            >
              <Typography
                id="keep-mounted-modal-title"
                variant="h6"
                component="h2"
              >
                Aggiorna l'elemento con id {id} alla tabella {table}
              </Typography>
              <Button
                color="error"
                onClick={() => handleOpen(false, "mod")}
                sx={{
                  maxWidth: "30px",
                  maxHeight: "30px",
                  minWidth: "30px",
                  minHeight: "30px",
                }}
              >
                <CloseIcon />
              </Button>
            </Box>
            {/* Autocomplete per il campo user_code */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={usersData}
              getOptionLabel={(option) => option.code || ""}
              value={
                selecteduser_code
                  ? usersData.find((user) => user.code === selecteduser_code)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelecteduser_code(newValue.code);
                  setValue("user_code", newValue.code);
                } else {
                  setSelecteduser_code(null);
                  setValue("user_code", "");
              }}}
              isOptionEqualToValue={(option, value) =>
                option.code === value.code
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select user_code"
                  placeholder="user_code"
                  error={!!errors.user_code}
                  helperText={
                    errors.user_code ? "Il campo user_code è obbligatorio" : ""
                  }
                  {...register("user_id")}
                />
              )}
            />
            {/* Autocomplete per il campo message_type_id */}
            <Autocomplete
              sx={{ width: "50%", m: 1 }}
              options={message_typesData}
              getOptionLabel={(option) => option.id || ""}
              value={
                selectedmessage_type_id
                  ? message_typesData.find((comunication) => comunication.id === selectedmessage_type_id)
                  : null
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  setSelectedmessage_type_id(newValue.id);
                  setValue("message_type_id", newValue.id);
                } else {
                  setSelectedmessage_type_id(null);
                  setValue("message_type_id", "");
              }}}
              isOptionEqualToValue={(option, value) =>
                option.id === value.id
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select message_type_id"
                  placeholder="message_type_id"
                  error={!!errors.message_type_id}
                  helperText={
                    errors.message_type_id ? "Il campo message_type_id è obbligatorio" : ""
                  }
                  {...register("user_id")}
                />
              )}
            />
						{Object.entries(prev)
              .filter(([key]) => true && key !== "code" && key !== "user_code" && key !== "message_type_id")
              .map(([key, value], index) => (
                <TextField
                  key={index}
                  placeholder={key}
                  defaultValue={value}
                  sx={{ width: "50%", m: 1 }}
                  {...register(key)}
                />
              ))}
            <Button onClick={handleSubmit(update)}>SALVA MODIFICHE</Button>
          </Box>
        </Modal>
      </>
    );
  }
};

export default Update;