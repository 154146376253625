import React from "react";

import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";

const ProfileBtn = () => {
    const navigate = useNavigate();

    return (
      <Button
        fullWidth={isMobile || (window.innerWidth <= 785 && true)}
        onClick={() => navigate("/profilo")}
        sx={{ color: !isMobile && "white", borderBottom: isMobile && "1px solid #ddd", mb: isMobile && 1 }}
      >
        PROFILO
      </Button>
    );
  };
export default ProfileBtn
		