import { useEffect, useState } from "react";

import { PulseLoader } from "react-spinners";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import { Box, Grid } from "@mui/material";

import useAuth from "../../hooks/auth/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const RequireAuth = ({ allowedRoles }) => {
  const [check, setCheck] = useState();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useAuth();
  const [finish, setFinish] = useState(false);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const response = await axiosPrivate.post("/token/check_token", {
          roles: allowedRoles,
        });
        setCheck(response.data?.result);
        setFinish(true);
      } catch (err) {
        console.error(err);
        navigate("/login", { state: { from: location }, replace: true });
      }
    };

    checkUser();
  }, [allowedRoles, axiosPrivate, location, navigate]);

  return (
    <Box>
      {finish ? (
        check ? (
          <Outlet />
        ) : auth?.user !== "" ? (
          <>
            {console.log("Unauthorized.")}
            <Navigate to="/unauthorized" state={{ from: location }} replace />
          </>
        ) : (
          <>
            {console.log("Unauthenticated.")}
            <Navigate to="/login" state={{ from: location }} replace />
          </>
        )
      ) : (
        <>
          {console.log("Loading . . .")}
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Box>
              <PulseLoader color="#83c4ff" margin="30px" />
            </Box>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default RequireAuth;
