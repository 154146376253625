import { Button } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";

const MyPracticesBtn = ({ type }) => {
  return (
    <Button
      fullWidth={(isMobile || window.innerWidth <= 785) && true}
      onClick={() => {
        if (type === "6093") window.location.href = "/le_mie_pratiche";
        else if (type === "2039")
          window.location.href = "/area_perito/le_mie_pratiche";
      }}
      sx={{
        color: !isMobile && "white",
        borderBottom: isMobile && "1px solid #ddd",
        mb: isMobile && 1,
      }}
    >
      LE MIE PRATICHE
    </Button>
  );
};

export default MyPracticesBtn;
