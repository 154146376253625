import React from "react";
import NavBar from "../../../../components/navbar/NavBar";
import { Typography } from "@mui/material";
import ElementsList from "../../../../components/ElementsList";
import { isMobile } from "react-device-detect";

const ControllerUsers = ({ type }) => {
  return (
    <>
      <NavBar type="8711" />
      <Typography
        variant="h5"
        color="primary"
        sx={{ mt: isMobile ? 12 : 3, mb: 1 }}
      >
        {type === "Repairer"
          ? "Riparatori"
          : type === "Intermediary" && "Periti"}
      </Typography>
      <ElementsList
        type="Users"
        user="Controller"
        variantSearchKeys={`where_key=role_name&where_value=${type}&order_key=created_at&order_desc=true&complete=true`}
      />
    </>
  );
};

export default ControllerUsers;
