import React, { useEffect, useRef } from "react";

import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Dialog, IconButton, Toolbar, Typography } from "@mui/material";

import useGetListOf from "../../hooks/useGetListOf";
import ElementsList from "../ElementsList";

const PracticeAssignModal = ({ open, onClose }) => {

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <AppBar sx={{ position: "relative", overflow: "hidden" }}>
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h6" component="div">
            Assegna la pratica
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          p: 2,
          height: 500,
          overflow: "auto",
          background: "#f9f9f9",
          borderRadius: 2,
        }}
      >
        <ElementsList
          type="Users"
          user="Controller"
          variantSearchKeys={`where_key=role_name&where_value=Intermediary&order_key=created_at&order_desc=true&complete=true`}
          variant="practice_assignment"
        />
      </Box>
    </Dialog>
  );
};

export default PracticeAssignModal;
