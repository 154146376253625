import React from "react";

import { isMobile } from "react-device-detect";

import { Box } from "@mui/material";

import MobileNav from "./supports/MobileNav";
import DesktopNav from "./supports/DesktopNav";
import useGetCurrentUserData from "../../hooks/auth/useGetCurrentUserData";

const NavBar = ({ type }) => {
    const currentUserData = useGetCurrentUserData();

  return (
    <>
      <Box
        sx={{
          mt: -1,
          mb: isMobile ? 6 : 2,
          pt: 1,
          pb: 1,
		  position: !isMobile && "sticky",
          top: 0,
          zIndex: 1000,
          background: "#005ca9",
          boxShadow: 1,
          maxHeight: isMobile ? 0 : 125,
        }}
      >
        {isMobile || window.innerWidth <= 785 ? (
          <MobileNav type={type} userData={currentUserData} />
        ) : (
          <DesktopNav type={type} userData={currentUserData} />
        )}
      </Box>
    </>
  );
};

export default NavBar;
