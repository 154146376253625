import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import useGetListOf from "../../../hooks/useGetListOf";
import LoadingSpinner from "../../layout/LoadingSpinner";
import { Box } from "@mui/system";

const PracticesNewType = ({
  handleSetValue,
  handleTabChange,
  accidentType,
}) => {
  const practiceTypesList = useGetListOf("Practice_Types", "", "list");
  const [selectedType, setSelectedType] = useState(accidentType);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (value) => {
    setLoading(true);
    setSelectedType(value);
    handleSetValue(value, "type");
    setTimeout(() => {
      handleTabChange("", 1);
      setLoading(false);
    }, "1000");
  };

  return (
    <>
      <Typography variant="h6" color="primary" sx={{ my: 2 }}>
        Scegli la tipologia di sinistro
      </Typography>
      {practiceTypesList && practiceTypesList.length !== 0 && (
        <FormControl
          sx={{ width: "100%", mb: loading ? 0 : 10 }}
          disabled={loading}
        >
          <InputLabel>Tipologia sinistro</InputLabel>
          <Select
            value={selectedType}
            label="Tipologia sinistro"
            onChange={(e) => handleChange(e.target.value)}
            open={open}
            onClick={(e) => {
              if (loading === false) setOpen(!open);
            }}
            disabled={loading}
          >
            {practiceTypesList.listElements.map((type) => (
              <MenuItem
                value={{ id: type.id, value: type.value }}
                onClick={(e) =>
                  handleChange({ id: type.id, value: type.value })
                }
              >
                {type.value}
              </MenuItem>
            ))}
          </Select>
          {loading == true && (
            <Box sx={{ mt: 1, mb: -1.2 }}>
              <LoadingSpinner />
            </Box>
          )}
        </FormControl>
      )}
    </>
  );
};

export default PracticesNewType;
