import React from "react";

import { Link } from "react-router-dom";

import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import { Box, Divider, Paper } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";

function createData(name, value) {
  return { name, value };
}

const ProfileData = ({ userData }) => {
  let rows = [];
  console.log(userData);

  if (userData.length !== 0 && userData !== null) {
    let [year, month, day] = ["", "", ""];

    if (
      userData &&
      userData.user_anagraphic &&
      userData.user_anagraphic["birth"]
    )
      [year, month, day] = userData.user_anagraphic["birth"].split("-");

    rows = [
      userData.user === "Admin" &&
        createData(
          "Tipo utente",
          userData.user ? userData.user["role"] || "" : ""
        ),
      createData(
        "Nome",
        userData.user_anagraphic ? userData.user_anagraphic["name"] || "" : ""
      ),
      createData(
        "Cognome",
        userData.user_anagraphic
          ? userData.user_anagraphic["surname"] || ""
          : ""
      ),
      createData("", ""),
      createData(
        "Ragione sociale",
        userData.user_anagraphic
          ? userData.user_anagraphic["business_name"] || ""
          : ""
      ),
      createData(
        "P.IVA",
        userData.user_anagraphic ? userData.user_anagraphic["p_iva"] || "" : ""
      ),
      // createData("Indirizzo", userData.user_address ? userData.user_address["address"] || "" : ""),
      // createData("Città", userData.user_address ? userData.user_address["city"] || "" : ""),
      // createData("CAP", userData.user_address ? userData.user_address["cap"] || "" : ""),
      // createData("Provincia", userData.user_address ? userData.user_address["province"] || "" : ""),
      // createData("Paese", userData.user_address ? userData.user_address["nation"] || "" : ""),
      createData("", ""),
      createData(
        "Telefono",
        userData.user_anagraphic
          ? userData.user_anagraphic["telephone"] || ""
          : ""
      ),
      createData("Email", userData.user ? userData.user["email"] || "" : ""),
    ];

    return (
      <Container component={Paper} maxWidth={"sm"} sx={{ pt: 2 }}>
        <TableContainer>
          <Table>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontSize: 20, fontWeight: "bold" }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell align="right" sx={{ fontSize: 20 }}>
                    {row.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Divider sx={{ my: 2 }} />
        <Button
          component={Link}
          to="/profilo/modifica_password"
          variant="contained"
          sx={{ mx: 1, width: "45%" }}
        >
          Cambio password
        </Button>
        <Button
          component={Link}
          to="/profilo/modifica"
          variant="contained"
          sx={{ mx: 1, width: "45%" }}
        >
          Modifica
        </Button>
        <br />
        <br />
      </Container>
    );
  }
};

export default ProfileData;
