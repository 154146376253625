import React from "react";
import NavBar from "../../../../components/navbar/NavBar";
import { Typography } from "@mui/material";
import { isMobile } from "react-device-detect";
import PracticesDetail from "../../../../components/practices/PracticesDetail";
import { useParams } from "react-router-dom";

const RepairerPracticeDetail = () => {
  const { codePractice } = useParams();
  return (
    <>
      <NavBar type="6093" />
      <Typography variant="h5" color="primary" sx={{ mt: isMobile ? 12 : 3, mb: 1}}>
        Dettaglio pratica
      </Typography>
      <PracticesDetail user="Repairer" practiceCode={codePractice} />
    </>
  );
};

export default RepairerPracticeDetail;

