import useAuth from "./useAuth";
import { axiosAuth } from "../../api/axios";

const useRefreshToken = () => {
	const { setAuth } = useAuth();

	const refresh = async () => {
		const response = await axiosAuth.get("/refresh", {
			withCredentials: true,
		});
		setAuth((prev) => {
			return {
				...prev,
				accessToken: response.data.result,
			};
		});
		return response.data.result;
	};
	return refresh;
};

export default useRefreshToken;	
	